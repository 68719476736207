.latest_news {
    background-image: url('../../assets/imgs/home/bg-news.jpg');
    min-height: 95vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding-left: var(--sidebar-width);
    margin-bottom: 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 992px) {
        padding-left: 0;
    }

    .section_title {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 92px;
        margin-bottom: 24px;
    }

    .title {
        font: normal normal normal 60px/50px Curse Casual;
        color: #0D1C2B;
        text-transform: capitalize;

        @media (max-width: 600px) {
            margin-left: 25px;
        }
    }

    .description {
        font: normal normal normal 14px/30px Roboto;
        color: #0D1C2B;

        @media (max-width: 600px) {
            margin-left: 25px;
        }
    }

    .news_scroll {
        display: flex;
        justify-content: center;
        padding-top: 35px;
        flex-wrap: wrap;

        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;
        }

        @media (max-width: 500px) {
            a:last-child {
                display: none;
            }
        }
    }
}
