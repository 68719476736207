.bridge_modal {
    position: absolute;
    display: flex;

    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 3px;
        right: 12px;
        color: #FFFFFF;
        cursor: pointer;
        z-index: 1;
    }

    .chest {
        color: #2330D0;
    }

    .bridge_modal_header {
        position: absolute;
        height: 136px;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #000000;
        justify-content: center;
        align-items: center;
        border-radius: 5px 5px 0px 0px;

        .header_texts {
            padding-top: 24px;
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #FFFFFF;
        }

        .header_texts_chest {
            color: #333333;
        }

        .progress_bar {
            width: 250px;
            margin-top: 16px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .bridge_modal_header_chest_bg {
        background: #FFFFFF;
    }

    .bridge_modal_body {
        position: relative;
        margin-top: 136px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        width: 100%;
        padding: 0 83px;

        @media screen and (max-width: 480px) {
            padding: 0 20px;
        }

        .amount {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.1px;
            margin-top: -20px;
        }

        .mode_icon {
            height: 48px;
            width: 48px;
        }

        .bridge_text {
            position: relative;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.1px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000000;
        }

        .bridge_item_type {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.2px;
            color: #828282;
        }

        .bridge_skin_type_rarity {
            display: flex;
            justify-content: center;
            align-items: center;

            .bridge_skin_type {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.2px;
                color: #828282;
                margin-right: 4px;
            }

            .bridge_skin_rarity {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 8px;
            }
        }

        .chest_wrapper, .ticket_wrapper, .skin_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 15px;

            .ticket_image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 45px;
                width: 62px;
            }

            .skin_image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 56px;
                width: 56px;
            }

            .tickets, .skin_count {
                font-family: Curse Casual;
                font-style: normal;
                font-weight: normal;
                font-size: 60px;
                line-height: 50px;
                letter-spacing: 0.1px;
                color: #333333;
                margin-left: 20px;
            }
        }

        .chests {
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 60px;
            line-height: 50px;
            letter-spacing: 0.1px;
            color: #333333;
            margin-left: 20px;
        }

        .transfer_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 8px;
            align-items: center;

            .bsc_wrapper, .polygon_wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                .modeText {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.2px;
                    color: #828282;
                }

                .bscIcon, .polygonIcon {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #EFEFEF;
                    padding: 2px;
                    margin: auto;
                    border-radius: 4px;
                }
            }

            .transferArrow {
                margin: auto 4px;
                height: 25px;
            }
        }

        .ethereum_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }

        .polygon_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }

        .ethereumIcon {
            width: 40px;
            height: 40px;
            background-color: #151C2F;
            padding: 2px;
            border-radius: 4px;
        }

        .polygonIcon {
            width: 40px;
            height: 40px;
            background-color: #F1E9FA;
            padding: 2px;
            border-radius: 4px;
        }

        .modeText {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #828282;
        }

        .trasferArrow {
            height: 25px;
        }

        .approve_button {
            width: 100% !important;
            margin-top: 50px;
            justify-content: center;
            align-items: center;
            padding: 10px 88px;
            background: #2330D0;
            border-radius: 4px;

            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: #F2F2F2;
            cursor: pointer;
        }

        .info_icon {
            margin-right: 5px;
        }

        .info_transfer {
            margin-top: 20px;
            padding: 14px 6px;
            background: #F2F2F2;
            border-left: 3px solid #2330D0;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            letter-spacing: 0.2px;
            color: #8A8A8A;
            width: 100%;
        }

        .strongtext {
            font-weight: bold;
            margin-left: 4px;
            margin-right: 4px;
            color: #0D1C2B;
        }

        .bsc_icon_background {
            background: #FFFFFF !important;
        }

        .polygon_icon_background {
            background: #F1E9FA;
        }
    }
}