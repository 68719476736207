.addtower_modal {
    display: flex;
    justify-content: center;

    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 3px;
        right: 12px;
        color: #2330D0;
        cursor: pointer;
        z-index: 1;
    }
    
    .addtower_modal_header {
        display: flex;
        position: relative;
        text-align: center;
        align-content: center;
        justify-content: center;

        .addtower_modal_title {
            font: normal normal normal 26px/32px Curse Casual;
            letter-spacing: 0.2px;
            color: #000000;
        }
    }

    .addtower_modal_body {
        align-self: center;
        width: 240px;
        height: 130px;
        margin-top: 22px;
        margin-bottom: 36px;
        background: #2330D0;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;        

        .addtower_modal_body_icon {
            align-self: center;

            img {
                width: 41px;
                height: 38px;
            }
        }

        .addtower_modal_body_text {
            align-self: center;
            font: normal bold normal 14px/30px Roboto;
            display: flex;
            align-items: center;
            letter-spacing: 0.1px;
            color: #FFFFFF;
            flex: none;
            margin: 0px 9px;
        }
    }

    .addtower_modal_footer {
        display: flex;
        justify-content: center;
        align-items: center;

        .addtower_modal_footer_earntower_text {
            font: normal normal normal 14px/20px Roboto;
            display: flex;
            align-items: center;
            letter-spacing: 0.1px;
            color: #000000;
        }

        .addtower_modal_footer_earntower_link {
            font: normal normal normal 16px/19px Curse Casual;
            display: flex;
            align-items: center;
            letter-spacing: 0.2px;
            text-decoration-line: underline;
            color: #2330D0;
            cursor: pointer;
        }
    }

    
}