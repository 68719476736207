.side_nav_container {
    width: var(--sidebar-width);
    position: fixed;
    background-color: rgba(0, 0, 0, 1);
    height: calc(100% + 43px);
    padding-top: 74px;
    display: flex;
    justify-content: center;
    z-index: 5;
    // Margin top and calc height for when TopRibbon is being rendered (Temp Fix). Need to Improve Nagivation for proper fix.
    margin-top: -43px;

    @media (max-width: 991.5px) {
        display: none;
    }

    .sidenav_head {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        // Intented to add scroll for small height screen, but causes submenu to hidden. Need better fix.
        // overflow-y: auto;
        // overflow-x: hidden;
    }

    .tower_logo {
        margin: 0.5rem 0;
        width: 60px;
        cursor: pointer;
    }

    .nav_links {
        display: flex;
        flex-direction: column;
        font: normal normal normal 20px/16px Curse Casual;
        color: #fff;
        margin-top: #{'max(50px, 6vh)'};
        width: 100%;
        
        svg {
            margin-left: 10px;
        }

        .link {
            margin: 8px 0;
            padding: 12px 0;
            padding-left: 20px;
            padding-right: 8px;
            cursor: pointer;
            position: relative;

            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }

        .about_link {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    
        .link_submenus {
            position: absolute;
            flex-direction: column;
            top: 0;
            left: var(--sidebar-width);
            width: 250px;
            padding-left: 8px;
            border-radius: 5px;
            z-index: 100;
    
            .about_contents {
                background-color: rgba(0, 0, 0, 0.84);

                a {
                    color: #fff;
                    text-decoration: none;
                }
    
                .about_page_link {
                    padding: 18px;
                }
    
                .about_page_link:hover {
                    background-color: rgba(255, 255, 255, 0.5);
                    border-radius: 5px;
                }
            }
        }

        .store_new_text_img {
            position: absolute;
            width: 50px;
            left: 0;
            top: -12px;
            transform: rotate(-8deg);
        }
    }    
}
