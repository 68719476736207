#sale-box {
    border-radius: 8px;
    box-shadow: 0px 3px 12px #0000001A;
    background-color: white;
    overflow: hidden;
    width: 100%;
    max-width: 450px;

    .sale-header {
        background-color: #0D1C2B;
        color: #FFF;
        padding: 16px;
        text-align: center;

        .header1 {
            font: normal normal normal 20px/23px Curse Casual;
        }

        .header2 {
            font: normal normal normal 14px/19px Roboto;
            margin-top: 4px;
        }
    }

    .sale-body {
        padding: 25px;
    }
    
    .disclaimer {
        margin-top: 4px;
        font-size: 12px;
        font: normal normal normal 14px/19px Roboto;
    }

    /* Overriding styles */
    ::-webkit-input-placeholder {
        font-size: 16px!important;
    }
    
    :-moz-placeholder { /* Firefox 18- */
        font-size: 16px!important;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
        font-size: 16px!important;
    }

    .err {
        color: red;
    }

    .sale-header {
        align-items: center;
        justify-content: space-around;

        @media (max-width: 768px) {
            flex-direction: column;
        }
        .center {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        h4 {
            margin: 0;
            font-size: 16px;
        }

        h3 {
            font-size: 48px;
        }

        h2 {
            padding: 0;
        }
    }

    #down {
        text-align: center;
        margin: 6px 0;
    }

    .from-token,.to-token {
        text-align: left;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        span.from-to {
            font-family: 'Roboto';
            font-weight: bold;
            font-size: 14px;
        }

        .token-field {
            flex: 2 0 150px;

            input {
                padding: 22px 12px;
            }
        }

        .dropdown {
            border: 1px solid #2330D0;
            border-radius: 4px;
            margin-left: 10px;
            flex: 0 0 100px;
        }

        @media (max-width: 768px) {
            width: 100%
        }
    }

    .from-token {
        @media (max-width: 768px) {
            margin-top: 10px;
        }
        
        .btn-token {
            padding: 0;

            > span {
                width: 50px;
                display: inline-block;
            }
        }
    }

    .disclaimer {
        @media (max-width: 768px) {
            text-align: center;
            margin-left: 0;
        }
    }

    .tower-icon {
        border: 1px solid #C7C7C7;
        border-radius: 4px;
        font: normal normal normal 14px/12px Curse Casual;
        letter-spacing: 0.56px;
        color: #0D1C2B;
        padding: 7px;
        margin-left: 10px;
        flex: 0 0 100px;

        span {
            width: 50px;
            display: inline-block;
        }
    }
    .no-nfts-warning{
        margin: auto;
        margin-top: 20px;
        width: 65%;
        text-align: center;
    }
    .btns-container {
        margin: auto;
        margin-top: 20px;
        width: 100%;
        background-color: #2330D0;
        border-radius: 4px;

        button {
            width: 100%;
            color: #FFF;
            font: normal normal normal 14px/24px Curse Casual;
        }
    }

    .err-msg {
        color: #F50631;
        margin-top: 5px;
        text-align: center;
    }

    .footer {
        text-align: center;

        .add_liquidity {
            color: #2330D0;
            font: normal normal normal 14px/12px Curse Casual;
            letter-spacing: 0.28px;
            padding: 35px;
        }

        .uniswap_footer {
            font: normal normal normal 14px/19px Roboto;
            color: #C7C7C7;
        }
    }
}


.dropdown-item {
    img {
        width: 30px;
        height: 36px;
    }
}