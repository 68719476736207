.general_modal {
    color: #0D1C2B;

    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 3px;
        right: 12px;
        color: #2330D0;
        cursor: pointer;
    }

    .modal_header {
        justify-content: center;

        .general_title {
            font: normal normal normal 20px/30px Curse Casual;
        }
    }

    .general_modal_body {
        text-align: center;
        font: normal normal normal 14px/20px Roboto;
    }

    .general_modal_footer {
        justify-content: center;
        flex-direction: column;
        font: normal normal normal 14px/12px Curse Casual;

        a {
            color: #2330D0;
        }

        .general_ok_btn {
            width: 120px;
        }
    }

    .view_on_etherscan {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 8px;
            width: 24px;
        }
    }
}