.subscribe_container {
    margin-right: 100px;

    @media (min-width: 992px) {
        flex: 0 0 450px;
    }

    @media (max-width: 992px) {
        margin-right: 0px;
    }

    @media (max-width: 500px) {
        width: 90%;
    }

    .title {
        font: normal normal normal 60px/50px Curse Casual;
    }
    
    .description {
        font: normal normal normal 14px/30px Roboto;
        margin-top: 0.5em;
    }

    .subscription_form {
        display: flex;
        margin-top: 12px;

        @media (max-width: 992px) {
            flex-direction: column;
        }

        .subscription_input {
            flex: 1;

            .form_input {
                margin-bottom: 0px;
            }
        }

        .subscribe_button {
            margin-left: 20px;
            flex: 0 0 120px;
            cursor: pointer;

            @media (max-width: 992px) {
                margin: 15px auto 0 auto;
                width: 150px;
                max-height: 40px;
            }
        }
    }
}
