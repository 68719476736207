.displaynone {
    display: none;
}

.claim_tower_container {
    // margin-top: #{"max(-150px, -20vh)"};
    // background-image: url('../../assets/imgs/bind/bg-witcher.jpg');
    // background-attachment: fixed;
    // background-position: left center;
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-color: #f9f9f9;

    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media (max-width: 992px) {
        margin-left: 0px;
    }

    @media (max-width: 640px) {
        width: 100%;
        top: -12%;
    }

    .bind_title {
        color: #69341D;
        font: normal normal normal 60px Curse Casual;
    }

    .bind_subtitle {
        font: normal normal normal 32px Curse Casual;
    }

    .bind_description {
        font: normal normal normal 14px Roboto;
    }

    .earn_tower_details {
        flex: 1;
        width: 100%;
        background-color: #3C1E11;
        border-radius: 15px;
        padding: 12px;
        margin-top: 1.25em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        max-height: 70px;
        font: 400 normal normal 14px Curse Casual;

        @media (max-width: 768px) {
            margin-top: 0px;
            max-height: 135px;
        }

        &.small_container {
            @media (max-width: 768px) {
                width: 92%;
                max-height: 90px;
                margin-bottom: 16px;
                margin-left: -4px;
            }
        }

        .right_panel {
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
                flex-direction: column;
            }
        }

        .cdh_logo {
            width: 100px;
            height: 50px;
            margin-right: 6px;

            @media (max-width: 768px) {
                width: 50%;
                height: 80px;
            }
        }

        .stores_logo {
            margin-left: 10px;

            @media (max-width: 768px) {
                width: 100%;
                margin-top: 5px;
                margin-left: -22px;
            }

            img {
                width: 30px;
                height: 30px;
                margin-left: 8px;
            }
        }
    }

    .bind_soon_container {
        background-image: url('../../assets/imgs/bind/bg-d-paper-short.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        min-height: 340px;
        display: flex;
        align-items: center;

        @media (min-width: 768px) {
            min-width: 650px;
        }

        @media (max-width: 768px) {
            background-image: url('../../assets/imgs/bind/bg-m-paper-short.png');
            max-width: 384px;
        }

        .bind_container_items {
            display: flex;
            flex-direction: column;
            padding: 32px;
            height: 100%;

            @media (max-width: 768px) {
                padding: 12px;
                align-items: center;
            }

            .bind_texts {
                padding: 0px 16px;
                width: 87%;
                margin-bottom: 8px;

                @media (max-width: 768px) {
                    width: 100%;
                }

                .coming_soon_title {
                    @media (max-width: 768px) {
                        line-height: 0.8;
                        margin-top: 1em;
                        width: 80%;
                    }
                }

                .coming_soon_subtitle {
                    @media (max-width: 768px) {
                        line-height: 1;
                        margin-top: 0.75em;
                        width: 80%;
                    }
                }

                .coming_soon_description {
                    @media (max-width: 768px) {
                        margin-top: 0.75em;
                        width: 100%;
                    }
                }
            }
        }

        .bind_description {
            margin-top: 0.5em;
            line-height: 1.35;
        }

        .witch_image {
            right: -15%;
            top: 10%;
            position: absolute;

            @media (max-width: 768px) {
                top: -4%;
                right: 0;
            }

            img {
                @media (max-width: 768px) {
                    max-width: 202px;
                    max-height: 194px;
                }
            }
        }
    }

    .section_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 24px;

        @media (max-width: 991px) {
            padding-top: 25vh;
        }

        @media (max-width: 768px) {
            padding: 0;
        }

        .claim_box_container {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            max-width: 924px;
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 30px 35px 35px 30px;
            position: relative;
            background-image: url('../../assets/imgs/bind/bg-i-paper.png');

            @media (min-width: 992px) {
                background-image: url('../../assets/imgs/bind/bg-d-paper-long.png');
            }

            @media (max-width: 768px) {
                background-image: url('../../assets/imgs/bind/bg-m-paper-long.png');
            }

            @media (max-width: 450px) {
                padding: 30px 24px 24px 20px;
            }

            .bind_box_divider_parent {
                display: flex;
                justify-content: center;

                .bind_box_divider {
                    width: 95%;
    
                    img {
                        width: 100%;
                    }
                }
            }

            .claim_box_header_container {
                    position: absolute;
                    top: -5%;
                    left: -4%;
    
                    @media (max-width: 991px) {
                        top: -3%;
                    }
    
                    @media (max-width: 768px) {
                        top: -4%;
                    }

                .claim_box_header_img {
                    position: absolute;
                    top: -20%;

                    @media (max-width: 768px) {
                        img {
                            width: 80%;
                        }
                    }
                }

                .claim_box_header_bar {
                    background-image: url('../../assets/imgs/bind/img-title-bar.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    width: 382px;
                    height: 63px;
                    color: #FFFFFF;
                    font: normal normal normal 32px Curse Casual;
                    padding: 6px 40px 15px 8%;
                    margin-left: 60px;
                    white-space: nowrap;

                    @media (max-width: 768px) {
                        font: normal normal normal 20px Curse Casual;
                        padding-top: 12px;
                        max-width: 280px;
                        height: 55px;
                        margin-left: 50px;
                    }
                }
            }

            .header {
                display: flex;
                justify-content: space-evenly;

                @media (max-width: 991px) {
                    flex-direction: column;
                }

                .header_texts {
                    flex: 1;
                    padding-top: 24px;
                    padding-left: 16px;

                    @media (max-width: 991px) {
                        padding-top: 8px;
                        width: 80%;
                    }

                    @media (max-width: 450px) {
                        padding-left: 6px;
                    }

                    .header_subtitle {
                        font: normal normal normal 32px Curse Casual;

                        @media (max-width: 768px) {
                            font: normal normal normal 26px Curse Casual;
                        }
                    }

                    .header_description {
                        margin-top: 4px;
                        display: block;
                        line-height: 1.5;
                        font: normal normal normal 14px Roboto;

                        @media (max-width: 768px) {
                            font: normal normal normal 12px Roboto;
                        }
                    }
                }

                .countdown {
                    display: flex;
                    padding: 6px 12px;

                    @media (max-width: 1199px) {
                        padding-top: 18px;
                    }

                    @media (max-width: 450px) {
                        padding-left: 2px;
                    }

                    > div {
                        margin-right: 12px;
                        text-align: center;

                        @media (max-width: 450px) {
                            margin-right: 2px;
                        }
                    }
                }
                .header_img {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .clock_box {
                    font: normal normal normal 26px Curse Casual;
                    width: 80px;
                    height: 95px;
                    border-radius: 8px;
                    background-color: #E5CCAF;
                    color: #69341D;
                    justify-content: center;

                    @media (max-width: 768px) {
                        font: normal normal normal 20px Curse Casual;
                        width: 51px;
                        height: 60px;
                        padding-top: 3px;
                    }

                    @media (max-width: 768px) {
                        width: 48px;
                    }

                    :first-child {
                        font: normal normal normal 32px Curse Casual;
                        
                        @media (min-width: 768px) {
                            font: normal normal normal 60px Curse Casual;
                        }
                    }

                    :last-child {
                        margin-top: -8px;
                    }
                }
            }

            .body {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 12px 18px;
                position: relative;

                @media (max-width: 768px) {
                    padding: 12px 8px;
                }

                .bind_section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;

                    .bind_section_title {
                        font: normal normal normal 32px Curse Casual;
                        color: #69341D;

                        @media (min-width: 768px) {
                            font: normal normal normal 60px Curse Casual;
                        }
                    }
                }

                .steps_button_section {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    @media (min-width: 992px) {
                        flex-direction: column;
                    }

                    .bind_steps {
                        display: flex;
                        margin: 20px 20px 20px 0px;

                        svg {
                            font-size: 26px;
                        }

                        @media (max-width: 991px) {
                            flex-direction: column;
                            align-items: center;
                        }
    
                        .bind_step {
                            width: 35px;
                            height: 35px;
                            text-align: center;
                            background-color: #C3A689;
                            border-radius: 24px;
                            font: normal normal normal 32px Curse Casual;
                            color: #9C7D5D;
    
                            &.active {
                                background-color: #69341D;
                                color: #FFFFFF;
                            }
                        }
    
                        .divider {
                            width: 180px;
                            height: 1px;
                            margin: 16px 2px;
                            border: 1px solid #69341D;

                            @media (max-width: 991px) {
                                width: 1px;
                                height: 50px;
                                margin: 0px 2px;
                            }

                            @media (max-width: 768px) {
                                width: 1px;
                                height: 35px;
                                margin: 0px 2px;
                            }
                        }
                    }
    
                    .bind_buttons {
                        display: flex;

                        @media (max-width: 991px) {
                            flex-direction: column;
                            align-items: center;
                            width: 60%;
                        }

                        @media (max-width: 768px) {
                            width: 100%;
                        }

                        > :nth-child(2) {
                            @media (max-width: 991px) {
                                margin: 35px 10px;
                            }

                            @media (max-width: 768px) {
                                margin: 18px 10px;
                            }
                        }
    
                        .step_button {
                            width: 100%;
                            min-width: 202px;
                            height: 50px;

                            @media (min-width: 992px) {
                                margin-right: 15px;
                            }
    
                            button {
                                width: 100%;
                                height: 50px;
                            }
                        }
    
                        .step_mini_container {
                            display: flex;
                            color: #6C3D0A;
                            font: normal normal normal 20px Curse Casual;
                            align-items: center;
                            justify-content: space-between;
                            border: 1px solid #C3A689;
                            border-radius: 4px;
                            padding: 4px 10px;
                            min-height: 50px;
    
                            svg {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .witch_image {
                position: absolute;
                top: 32%;
                right: -8%;

                @media (min-width: 1200px) {
                    right: -10%;
                }

                @media (max-width: 991px) {
                    right: -12%;
                    top: 0%;
                }

                @media (max-width: 768px) {
                    right: 3%;
                    top: 5%;
                }

                @media (max-width: 450px) {
                    right: -4%;
                    top: 7%;
                }

                img {
                    @media (max-width: 991px) {
                        width: 80%;
                    }

                    @media (max-width: 768px) {
                        width: 142px;
                        height: 138px;
                    }
                }
            }
        }

        .registered {
            display: flex;
            flex-direction: column;
            flex: 1;
            max-width: 650px;
            height: 310px;
            background-image: url('../../assets/imgs/bind/bg-d-paper-short.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding: 32px;
            align-items: center;

            @media (max-width: 768px) {
                background-image: url('../../assets/imgs/bind/bg-m-paper-short.png');
                max-width: 384px;

                .divider {
                    img {
                        width: 100%;
                    }
                }
            }

            .header_text {
                font: normal normal normal 32px Curse Casual;
                color: #69341D;
            }

            .registered_body {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0.5em;

                @media (max-width: 768px) {
                    flex-direction: column;
                }

                .game_image {
                    width: 175px;
                    height: 88px;
                }

                .description {
                    flex: 1;
                    padding: 0px 24px 12px;
                    font: normal normal normal 14px Roboto;
    
                    .title {
                        color: #9C7D5D;
                    }
    
                    .text {
                        margin-top: 2px;
                        font: normal normal normal 20px Curse Casual;
                        background-color: #E5CCAF;
                        border-radius: 4px;
                        padding: 4px 10px;
                    }

                    .user_details_section {
                        .user_details_header {
                            margin: 8px 0;
                        }
                        
                        .user_details {
                            margin-top: 6px;
                            display: flex;

                            @media (max-width: 768px) {
                                flex-direction: column;

                                > :first-child {
                                    margin-bottom: 8px;
                                }
                            }
    
                            .user_detail_box {
                                min-width: 160px;
                                min-height: 35px;
                                background-color: #E5CCAF;
                                border-radius: 4px;
                                padding: 6px 12px;
                                font: 400 normal normal 14px Curse Casual;
    
                                img {
                                    margin-right: 6px;
                                }

                                @media (max-width: 768px) {
                                    width: 100%;
                                }
                            }
    
                            > :first-child {
                                margin-right: 16px;
                            }
                        }
                    }

                    .logout_button_container {
                        margin-top: 4px;

                        @media (max-width: 768px) {
                            display: flex;
                            justify-content: center;
                        }

                        .logout_button {
                            background-color: transparent;
                            margin-top: 8px;
                            width: 50%;
                        }
                    }
                }
            }
        }

        .registered_added_text {
            font: 400 normal normal 20px Curse Casual;
            margin-top: 4px;
        }
    }
}

.loading_modal {
    text-align: center;

    .loading_text {
        font: 400 normal normal 20px Curse Casual;
        padding-bottom: 24px;
    }

    img {
        width: 100%;
        max-width: 350px;
    }
}

.login_modal {
    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 3px;
        right: 12px;
        color: #2330D0;
        cursor: pointer;
        z-index: 1;
    }

    .login_modal_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;

        .header_texts {
            text-align: center;
            font: normal normal normal 20px/30px Curse Casual;
            letter-spacing: 0px;
            color: #0D1C2B;
            padding-top: 24px;
        }
    }
}

.bind_modal {
    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 3px;
        right: 12px;
        color: #2330D0;
        cursor: pointer;
        z-index: 1;
    }

    .header {
        margin-top: 12px;
        text-align: center;

        .title {
            font: normal normal normal 20px Curse Casual;
        }
    }

    .bind_section {
        display: flex;
        justify-content: center;
        margin-top: 18px;

        .bind_entity {
            width: 200px;
            padding: 18px 32px;
            border-radius: 8px;
            background-color: #FAFAFA;
            display: flex;
            flex-direction: column;
            overflow-wrap: break-word;
            color: #333333;
            font: normal normal normal 14px Roboto;

            @media (max-width: 768px) {
                width: 160px;
                padding: 18px 12px;
            }

            .image_container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60px;
                margin-bottom: 12px;

                .cdh_logo {
                    width: 140px;
                    height: 70px;
                }

                .metamask_logo {
                    width: 40px;
                    height: 40px;
                }
            }

            .entity_title {
                color: #C7C7C7;
                font: normal normal normal 10px Roboto;
            }

            .entity_description {
                margin-top: 4px;
                color: #333333;
                font: normal normal normal 20px Curse Casual;
                display: flex;
                align-items: center;
            }

            .copy_button {
                color: #C7C7C7;
                margin-left: 12px;
                cursor: pointer;
                font-size: 18px;
            }

            .bind_user_details {
                margin-top: 6px;

                .user_detail {
                    width: 140px;
                    height: 36px;
                    background-color: #E8E8E8;
                    border: 1px solid #C7C7C7;
                    border-radius: 4px;
                    padding: 5px 8px;
                    color: #000000;
                    font: normal normal normal 14px Curse Casual;
                    margin-top: 8px;

                    img {
                        margin-right: 4px;
                        margin-bottom: 3px;
                    }
                }
            }
        }

        .bind_logo {
            min-width: 33px;
            height: 33px;
            text-align: center;
            margin: 100px -10px;
            z-index: 2;
            color: #FFFFFF;
            background-color: #2330D0;
            border-radius: 20px;

            svg {
                font-size: 1.3em;
                margin-top: 6px;
            }
        }

        .bind_logo_disabled {
            background-color: #C7C7C7;
        }
    }

    .footer {
        margin-top: 1em;
        display: flex;
        padding: 0px 10%;
        font: normal normal normal 14px Roboto;

        @media (max-width: 768px) {
            padding: 0px 0px 0px 4px;
        }

        .checkbox {
            margin-top: 2px;
            margin-right: 8px;
            cursor: pointer;
        }

        div {
            cursor: pointer;
        }
    }

    .footer_button {
        margin-top: 18px;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;

        button {
            min-width: 180px;
        }
    }
}

.bind_error_modal {
    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 3px;
        right: 12px;
        color: #2330D0;
        cursor: pointer;
        z-index: 1;
    }

    .bind_error_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .bind_error_texts {
            width: 90%;

            .bind_title {
                font: normal normal normal 26px Curse Casual;
                color: #2330D0;
            }
    
            .bind_subtitle {
                margin-top: 0.5em;
                font: 700 normal normal 16px Roboto;
            }
    
            .bind_error_code {
                margin-top: 4px;
                font: normal normal normal 16px Roboto;
            }
    
            .bind_footer {
                margin-top: 2.5em;
    
                Button {
                    width: 160px;
                }
            }
        }
    }
}

.mobile_only {
    @media (min-width: 992px) {
        display: none;
    }
}

.web_only {
    @media (max-width: 991px) {
        display: none;
    }
}