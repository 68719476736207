.news_container {
    width: 340px;
    min-width: 340px;
    max-width: 380px;
    min-height: 420px;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #0000000D;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    margin-right: 28px;
    margin-bottom: 28px;

    @media (max-width: 767px) {
        margin-right: 0px;
    }

    &:hover {
        text-decoration: none;
    }
    
    .image_container {
        width: 340px;
        height: 190px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px 8px 0px 0px;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .news_content {
        display: block;
        padding: 24px;

        img {
            border-radius: 4px;
            width: 125%;
        }
    }

    .title {
        font: normal normal normal 20px/30px Curse Casual;
        color: #0D1C2B;
        margin-top: 0.5em;
        margin-bottom: 0.8em;
    }

    .description {
        font: normal normal normal 14px/20px Roboto;
        color: #0D1C2B;
        min-height: 70px;
        max-height: 120px;
        overflow: hidden;
        text-align: justify;
    }

    .time {
        font: normal normal normal 14px/24px Roboto;
        color: #DADADA;
        margin-top: 12px;
    }
}
