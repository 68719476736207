@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic);

.card {
    max-width: 220px;
    position: relative;
    margin: auto;
    
    @media screen and (max-width: 535px) {
        max-width: 185px;
        height: fit-content;
    }
    @media screen and (max-width: 464px) {
        margin: 0px 4px 5px ;
    }
    // @media screen and (max-width: 401px) {
    //     transform: scale(0.85);
    // }
    // @media screen and (max-width: 318px) {
    //     transform: scale(1.15);
    // }
}
.cardImg {
    padding: 10px 28px;
    background:  #EBEBEB;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 164px;
        height: 201px;
        cursor: pointer;

        @media screen and (max-width: 535px) {
            max-width: 138px;
            height: 168px;
        }
        @media screen and (max-width: 359px) {
            transform: scale(0.85);
        }
        // @media screen and (max-width: 218px) {
        //     transform: scale(1.15);
        // }
    }
}

.heading {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: #0D1C2B;
}
    
.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff !important;
    padding: 0.75rem 7px !important;

    span {
        cursor: pointer;
    }

    .polyIcon {
        margin-right: 5px;
    }
}


.cardBody {
    padding: 10px;
    display: flex;
    justify-content: center;

    .title {
        @extend .heading
    }

    .lowerBody {
        margin-top: 6px;
        display: flex;
        align-items: center;
    }

    .bodyContent {
        margin-left: 8px;
        h5 {
            font-size: 10px;
            font-weight: 400;
            font-family: 'Roboto', sans-serif;
            line-height: 14px;
            letter-spacing: 0.2px;
            color: #8A8A8A;
            margin-bottom: 2px;
        }
        .time {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: 'Roboto', sans-serif;
            letter-spacing: 0.2px;
            margin-bottom: 0;

            span {
                color: #8A8A8A;
            }
        }
    }
}

.overlay {
    padding: 7px 10px;
    position: absolute;
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    top: 0;
    left: 0;
    background: #fff;
    transition: opacity 0.5s ease-in-out; 
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    border-radius: 4px;
    
    .closeBtn {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
    }

    .title {
        @extend .heading;
        text-align: center;
    }
    .shareLinks {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 70%;

        li {
            min-width: 100px;
            cursor: pointer;
            &:not(:last-child) {
                margin: 13px;

                @media (max-width: 535px) {
                    margin-bottom: 15px;
                }
            }
           img {
               margin-right: 12px;
               width: 24px;
               height: 24px;
           }
           a, span {
               font-size: 14px;
               line-height: 17px;
               letter-spacing: 0.1px;
               font-weight: 400;
               font-family: 'Roboto', sans-serif;
               color: #000;
               text-decoration: none;
           }

           .copyToClipboard {
               cursor: pointer;
           }
        }

        @media screen and (max-width: 535px) {
            margin-top: 0;
            margin-bottom: 16px;
        }
    }

    .transferBtn {
        width: 100%;
        display: block;
        border: none;
        background-color: #2330D0;
        border-radius: 4px;
        color: #FFF;
        text-align: center;
        padding: 6px 13px;

        @media (max-width: 343px) {
            padding: 3px 3px;
        }
                
        &:hover {
          color: #FFF;
        }

        img {
            margin-right: 7px;
        }
    }
}
.open {
    padding-top: 25px;
    height: 100%;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    @media screen and (max-width: 500px) {
        padding-top: 25px;
    }
}
