.col {
    @media screen and (max-width: 594px) {
        flex: 0 0 50%;
        max-width: 50% !important;
    }
    @media screen and (min-width: 992px) and (max-width: 1132px) {
        flex: 0 0 33.33% !important;
        max-width: 33.33% !important;
    }
    @media screen and (max-width: 464px) {
       padding-left: 0 !important;
       padding-right: 0 !important;
       margin-bottom: 0 !important;
    //    transform: scale(0.85);
    }
    @media screen and (min-width: 464px) {
        margin-bottom: 1.5rem !important;
    }
    @media screen and (max-width: 318px) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.container {

    .list_filtering {
        display: flex;
        justify-content: flex-end;
        margin: 0 1em 0.8em 1em;

        select {
            width: 150px;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 0.25rem;
            height: 2em;
            
            @media screen and (max-width: 464px) {
                width: 100%;
            }
        }        
    }
}

.paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;

    @media screen and (max-width: 495px) {
        flex-direction: column;
    }

    
    .pageInfo {
        display: flex;
        align-items: center;
        @media screen and (max-width: 495px) {
            margin-top: 8px;
        }
        .currentPage {
            padding: 2px 15px;
            border-radius: 4px;
            border: 1px solid #8A8A8A;
            color: #8A8A8A;
            background: #E5E5E5;
            margin-right: 20px;
            font: normal normal 400 12px/16px 'Roboto';
        }
        span {
            font: normal normal 400 12px/16px 'Roboto';
            letter-spacing: 0.2px;
            color: #8A8A8A;
        }
    }
}

.paginationBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin-bottom: 0;

    

    li {
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        a:not(.activeBtn) {
            font: normal normal 400 12px/16px 'Roboto';
            color: #8A8A8A;
            letter-spacing: 0.2px;
        }

        &.activeBtn {
            background: #8A8A8A;
            padding: 2px 8px;
            border-radius: 4px;
             a {
                 color: #fff;
             }
        }
    }
}

.network_message {
    text-align: center;
    display: flex;
    align-items: center;

    .switch_btn {
        height: 28px;
        max-width: 100px;
        font-size: 14px;
        line-height: 14px;
        margin: 8px;
        background-color: #2330D0;
    }

    @media (max-width: 992px) {
        flex-direction: column;
        font-size: 14px;
    }
}
