.footer_logo1 {
    background-image: url('../../assets/imgs/home/bg-keep-in-touch.png');
    display: flex;
    // position: absolute;
    // top: -87px;
    // right: 0;
    height: 260px;
    width: 380px;
    background-repeat: no-repeat;
    // @media (max-width: 992px) {
    //   position: relative;
    //   display: block;
    //   top: 0px;
    // }

    .FormGroup {
        width: 88%;
        padding-top: 50px;
        padding-left: 50px;
        label {
            //styleName: H4;
            font-family: Curse Casual;
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            color: #69341d;
            letter-spacing: 0.20000000298023224px;
            text-align: left;
        }
        input {
            flex: 1;
            height: 45px;
            width: 85%;
            left: 0px;
            top: 30px;
            border-radius: 4px;
            padding: 10px;

            outline: none;
            border: none;
        }
        .subscribe_button {
            margin-top: 10px;
        }
    }

    .footer_logo {
        display: flex;
        position: absolute;
        bottom: -48px;
        right: -185px;
        height: 214px;
        
        @media (max-width: 992px) {
            display: none;
        }
    }
}
