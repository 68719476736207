.TopRibbon {
    width: 100%;
    padding: 10px;
    background-color: #2330D0;
    font: normal normal normal 20px/23px Curse Casual;
    color: white;
    text-align: center;

    z-index: 1000;
    position: relative;
}