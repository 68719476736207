
.home_section_title {
    background-image: url('../../assets/imgs/home/tit-paper.png');
    background-repeat: no-repeat;

    background-size: contain;
    background-position: top center;

    height: 84px;
    max-width: 455px;
    width: 100%;

    .title_text {
        font: normal normal normal 32px/37px Curse Casual;
        -webkit-text-stroke: 1.5px #000;
        -webkit-text-fill-color: #ffffff;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media (max-width: 500px) {
            font-size: 28px;
            height: 64px;
        }
        
        @media (max-width: 300px) {
            font-size: 24px;
            height: 48px;
        }   
    }
}