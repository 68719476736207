@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
    font-family: 'Curse Casual';
    font-style: normal;
    font-style: normal;
    src: url('../assets/fonts/Curse-Casual/Curse-Casual.ttf') format('truetype');
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-style: normal;
    src: url('../assets/fonts/Playfair-Display/PlayfairDisplay-VariableFont_wght.ttf')
        format('truetype');
}

h2 {
    font-family: 'Curse Casual', sans-serif;
}

p {
    font-family: 'Roboto', sans-serif;
}

.modal-backdrop.show {
    z-index: 1025;
}

.modal.show {
    z-index: 1026;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        align-items: center;
    }
}

// .discordBoard {
//     position: fixed;
//     bottom: -3px;
//     z-index: 1000;
//     right: 0px;
//     display: block;
//     width: 199px;
//     display: block;
//     @media (max-width: 992px) {
//         display: none;
//     }
// }
// .discordBoard:hover {
//     bottom: 0px;
// }

.loading {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.app-blocked-notice {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000;
    color: #fff;
    font: normal normal normal 26px/28px Curse Casual;
    padding: 24px;
    flex-direction: column;

    .caution-icon {
        img {
            width: 48px;
            height: 48px;
        }
    }

    .blocked-text {
        margin-top: 20px;
        text-align: center;
    }
  
    span {
      text-align: center;
    }
  }


:root {
    --sidebar-width: 172px;
}