.bayc_container {
    margin-left: var(--sidebar-width);
    background-color: #FAFAFA;
    margin-top: -75px;

    @media (max-width: 991.5px) {
        margin-left: 0px;
    }

    .bayc_body {
        // background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/imgs/bayc/bayc_bg.png');
        background: linear-gradient(to top, #AC8D6E 1%, transparent, transparent), url('../../assets/imgs/bayc/bayc_bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        min-height: 768px;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -135px;
        position: relative;
        overflow: hidden;

        @media (max-width: 992px) {
            margin-top: 0;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 1;
        }

        .content::before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #241109;
            opacity: 0.77;
            filter: blur(47.5px);
            border-radius: 101px;
            z-index: -1;
        }

        @media (max-width: 768px) {
            // background: linear-gradient(to top, black 20%, transparent), url('../../assets/imgs/bayc/bayc_bg.png');
            background: linear-gradient(to top, #AC8D6E 10%, transparent, transparent), url('../../assets/imgs/bayc/bayc_bg.png');
            align-items: flex-end;

            .content {
                position: absolute;
                top: 150px;
            }

            .content::before {
                background: linear-gradient(360deg, #0E0A08 49.54%, rgba(14, 10, 8, 0) 89.91%);
            }
        }

        .avatar_ape {
            position: absolute;
            left: -80px;
            bottom: 10%;
            width: 50%;
            max-width: 744px;
            min-width: 582px;
        }

        .avatar_hero {
            position: absolute;
            right: 0;
            bottom: 10%;
            width: 25%;
            max-width: 404px;
            min-width: 300px;
        }

        @media (max-width: 640px) {
            .avatar_ape {
                max-width: 364px;
                min-width: initial;
                bottom: -190px;
                left: -132px;
                width: 100%;
                transform: translate(20%, -90%);
            }

            .avatar_hero {
                max-width: 204px;
                min-width: initial;
                bottom: -160px;
                right: -50px;
                width: 100%;
                transform: translate(-20%, -90%);
            }
        }

        // @media (max-width: 768px) {
        //     .avatar_ape {
        //         max-width: 352px;
        //         min-width: initial;
        //         top: 50%;
        //         left: 0;
        //         width: 100%;
        //         transform: translate(20%, -90%);
        //     }

        //     .avatar_hero {
        //         max-width: 204px;
        //         min-width: initial;
        //         top: 50%;
        //         right: 0;
        //         width: 100%;
        //         transform: translate(-20%, -90%);
        //     }
        // }

        // @media (max-width: 540px) {
        //     .avatar_ape {
        //         left: -50px;
        //         transform: translate(0, -90%);
        //     }

        //     .avatar_hero {
        //         right: -20px;
        //         transform: translate(0, -90%);
        //     }
        // }

        .bayc_ape_helm {
            width: 98px;

            img {
                width: 100%;
                object-fit: contain;
                transform: rotate(15.02deg);
            }

            @media (max-width: 768px) {
                display: none;
            }
        }

        .text_content_div {
            // z-index: 1;
            // background-color: rgba(0, 0, 0, 0.25);
            // box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.25);
            // border-radius: 80px;

            .row1 {
                color: #DDDDDD;
                font: normal normal 700 20px/24px Roboto;
                text-align: center;
            }

            .row2 {
                background: linear-gradient(180deg, #FFFFFF 0%, #FFD1A6 100%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                color: #DDDDDD;
                font: normal normal normal 45px/47px Curse Casual;
                filter: drop-shadow(4px 4px #69341D);
                -webkit-text-stroke: 0.6px #69341D;
                letter-spacing: 0.1px;
                text-align: center;
                margin: 16px 0;
                padding: 0 16px;
                display: block;
                max-width: 540px;
                width: 100%;

                .row2_span {
                    color: #FFAF00;
                    -webkit-text-fill-color: initial;
                    filter: drop-shadow(4px 4px #69341D, 5px 5px 5px #82C3FF);
                }

                .row2_span::before {
                    content: "";
                    text-shadow: 4px 4px #69341D;
                }

                @media (max-width: 768px) {
                    font-size: 32px;
                    line-height: 37px;
                }
            }

            .row3 {
                color: #F7F7F7;
                font: normal normal normal 18px/24px Roboto;
                text-align: center;
                max-width: 575px;
                padding: 0 8px;

                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .check_my_progress_wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 100px;


            .check_my_progress_btn {
                background: url('../../assets/imgs/bayc/check_progress_button.png');
                background-size: 100% 100%;
                width: 240px;
                height: 70px;
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font: normal normal 400 26px/32px 'Curse Casual';
                letter-spacing: 0.2px;
                color: #FFFFFF;
                text-shadow: black 3px 1px 1px;
                cursor: pointer;
                -webkit-text-stroke: 1px #000000;
            }

            .check_my_progress_btn:hover {
                filter: brightness(110%);
            }

            .view_event_details {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font: normal normal 400 14px/20px 'Roboto';
                letter-spacing: 0.1px;
                text-decoration-line: underline;
                color: #FFFFFF;
                cursor: pointer;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }

        .social_media_btn_group {
            display: flex;
            flex-direction: row;
            margin-top: 16px;
            gap: 8px;
            z-index: 1;

            .social_media_btn {
                background: url('../../assets/imgs/btn_primary.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                width: 128px;
                height: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
                font: normal normal normal 14px/18px Curse Casual;
                color: #fff;
                padding: 4px 0;
                cursor: pointer;
                border: none;

                img {
                    width: 22px;
                    object-fit: contain;
                    margin-right: 10px;
                }

                svg {
                    width: 22px;
                    margin-right: 4px;

                    path {
                        fill: #FFFFFF;
                    }
                }
            }

            .social_media_btn:hover {
                filter: brightness(110%);
            }
        }

        .float_bottom {
            margin-top: 150px !important;
            min-width: 100%;

            display: none;
            margin: 16px 0;
            max-width: 334px;
            z-index: 1;
            position: absolute;
            bottom: 0;

            .bottom_text {
                font: normal normal normal 14px/18px Roboto;
                text-align: center;
                // color: rgba(255, 255, 255, 0.5);
                color: #69341D;
                // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                padding: 0 16px;
            }

            .bottom_img_group {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 15px;
                margin-top: 20px;

                .qr_img {
                    width: 65px;
                }

                .store_btn {
                    width: 110px;
                }
            }

            @media (max-width: 992px) {
                display: block;

                .bottom_img_group {

                    .store_btn {
                        width: 164px;
                    }
                }
            }
        }
    }

    .bayc_earn_status {
        background: #AC8D6E;
        padding: 64px 24px;
        overflow: auto;

        @media (max-width: 768px) {
            padding: 48px 6px 32px;
        }

        .bayc_earn_status_title {
            background: url('../../assets/imgs/bayc/earn_decorator.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            display: flex;
            max-height: 144px;
            max-width: 630px;
            width: 100%;
            position: relative;
            justify-content: center;
            align-items: center;
            margin: auto;

            .bayc_earn_text {
                font: normal normal 400 32px/37px 'Curse Casual';
                display: flex;
                align-items: center;
                letter-spacing: 0.1px;
                color: #FFFFFF;
                padding: 18px;
            }

            .bayc_earn_ape {
                position: absolute;
                width: 144px;
                height: 144px;
                z-index: 2;
                left: 80px;

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            @media (max-width: 768px) {
                max-width: 400px;

                .bayc_earn_text {
                    padding: 0;
                }

                .bayc_earn_ape {
                    display: none;
                }
            }
        }

        .bayc_earn_status_container {
            width: fit-content;
            margin: auto;
            margin-top: -8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background: url('../../assets/imgs/bayc/earn_bg.png');
            background-position: center;
            background-size: 100% 100%;
            padding: 48px 48px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            @media (max-width: 768px) {
                margin-top: -2px;
                padding: 48px 20px;
                background: url('../../assets/imgs/bayc/earn_bg_mobile.png');
                background-position: center;
                background-size: 100% 100%;
            }

            .faded_bored_ape {
                position: absolute;
                bottom: 24px;
                right: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .step_with_title {
                display: flex;
                align-items: center;
                padding: 0 4px;
                gap: 8px;

                .step_one {
                    background: url('../../assets/imgs/bayc/steps_number_container.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    min-width: 52px;
                    min-height: 52px;
                    font: normal normal 400 26px/32px 'Curse Casual';
                    letter-spacing: 0.2px;
                    color: #69341D;
                }

                .title {
                    font: normal normal 400 32px/37px 'Curse Casual';
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.1px;
                    color: #69341D;
                    background: linear-gradient(180deg, #FFFFFF 20%, #FFF3DF 100%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    filter: drop-shadow(2px 2px #DFB086);
                    -webkit-text-stroke: 6px transparent;
                    -webkit-text-fill-color: #69341D;
                }

                @media (max-width: 768px) {
                    .step_one {
                        min-width: 44px;
                        min-height: 44px;
                        font-size: 20px;
                    }

                    .title {
                        font-size: 28px;
                        text-align: center;
                    }
                }
            }

            @media (max-width: 768px) {
                .step_with_title {
                    justify-content: center;
                }
            }

            .you_are_all_set {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 33px;
                gap: 5px;
                background: rgba(182, 244, 185, 0.53);

                .you_are_all_set_text {
                    display: flex;
                    font: normal normal 400 26px/32px 'Curse Casual';
                    text-align: center;
                    letter-spacing: 0.2px;
                    color: #008A06;
                }
            }

            .my_invasion_status {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                @media (max-width: 640px) {
                    flex-direction: column;
                    justify-content: center;
                }

                .connect_game_id {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    gap: 2px;

                    @media (max-width: 640px) {
                        justify-content: center;
                        align-items: center;
                        margin-top: 12px;
                    }

                    .warning_text {
                        font: normal normal 400 14px/16px 'Curse Casual';
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.2px;
                        color: #E0001A;
                        margin-bottom: 8px;
                    }

                    .connect_game_id_button {
                        cursor: pointer;
                        background: url('../../assets/imgs/btn_primary_long.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        width: 174px;
                        height: 40px;
                        display: flex;
                        justify-content: center;

                        .connect_game_id_text {
                            font: normal normal 400 14px/18px 'Curse Casual';
                            display: flex;
                            margin-top: -4px;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: #F2F2F2;
                        }

                    }

                    .connect_game_id_button:hover {
                        filter: brightness(110%);
                    }

                    .user_assets {
                        display: flex;
                        align-items: flex-end;
                        flex-direction: column;
                        font: normal bold normal 14px/18px Roboto;
                        margin: 14px 0 8px 0;
                        color: #69341D;

                        .user_asset_section {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                        }

                        .token_boost_info {
                            display: flex;
                            position: relative;


                            .battle_card_count {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .count {
                                    margin-right: 5px;
                                }
                            }

                            .info_text {
                                position: relative;
                                justify-content: center;
                                align-items: center;
                                display: flex;
                                cursor: pointer;
                            }

                            .info_popup_box {
                                position: absolute;
                                display: none;
                                justify-content: center;
                                align-items: flex-start;
                                flex-direction: column;
                                box-sizing: border-box;
                                background: #FFECD7;
                                border: 1px solid #6C3D12;
                                border-radius: 4px;
                                left: 50px;
                                padding: 10px;
                                z-index: 1;
                                top: 10px;
                                width: 300px;
                                height: 105px;

                                .info_popup_description {
                                    font: normal normal 400 14px/18px Roboto;
                                    align-items: center;
                                    letter-spacing: 0.1px;
                                    color: #000000;
                                }

                                .info_description_link {
                                    cursor: pointer;
                                    align-items: center;
                                    letter-spacing: 0.1px;
                                    text-decoration-line: underline;
                                    color: #2330D0;
                                }
                            }
                        }

                        .info_text:active+.info_popup_box {
                            display: block;
                        }

                        .info_text:hover+.info_popup_box {
                            display: block;
                        }

                        .info_popup_box:hover {
                            display: block;
                        }

                        .item_info {
                            display: flex;
                            align-items: center;
                            padding: 5px 10px;
                            margin: 2px;
                            background: #E1C8AF;
                            border-radius: 80px;

                            .item_img {
                                width: 24px;
                                height: 24px;
                                margin-right: 8px;
                                padding: 1px;
                                display: flex;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .logout_btn {
                                width: 24px;
                                height: 24px;
                                cursor: pointer;
                                margin-left: 4px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        @media (max-width: 768px) {
                            flex-direction: column;
                            font-size: 11px;
                            line-height: 14px;
                            align-items: center;

                            .item_info {

                                .item_img {
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
            }

            .hero_point_description {
                margin: 10px 0px;
                padding: 0 4px;
                width: 100%;
                max-width: 900px;
                font: normal normal 400 16px/22px 'Roboto';
                display: block;
                justify-content: flex-start;
                align-self: flex-start;
                letter-spacing: 0.1px;
                color: #69341D;

                a {
                    color: #69341D;
                    text-decoration-line: underline;
                    font: normal normal 400 14px/20px 'Roboto';
                    letter-spacing: 0.1px;
                }
            }

            .bayc_earn_status_content_box {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                position: relative;
                padding: 48px 16px;
                width: 100%;
                gap: 15px;
                border: 2px solid #69341D;
                border-radius: 10px;

                .bayc_earn_status_hero_points_title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    height: 50px;
                    position: absolute;
                    top: -25px;
                    background: #EDD9C3;
                    font: normal normal 400 45px/47px 'Curse Casual';
                    color: #69341D;

                    span {
                        margin: 0 6px;
                    }

                    .bayc_earn_status_hero_points_brown_text {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.1px;
                        background: linear-gradient(180deg, #FFFFFF 20%, #FFF3DF 100%);
                        -webkit-background-clip: text;
                        background-clip: text;
                        filter: drop-shadow(2px 2px #DFB086);
                        -webkit-text-stroke: 6px transparent;
                        -webkit-text-fill-color: #69341D;
                        text-align: center;
                    }

                    .bayc_earn_status_hero_points_yellow_text {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.1px;
                        background: linear-gradient(180deg, #FFFFFF 14.28%, #FFE457 71.46%, #FFB915 85.11%);
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        filter: drop-shadow(2px 2px #DFB086);
                        -webkit-text-stroke: 2px #69341D;
                    }

                    .bayc_earn_status_hero_points_hero_point_logo {
                        display: flex;
                        width: 45px;
                        height: 45px;

                        img {
                            width: 100%;
                            object-fit: contain;
                        }
                    }

                    @media (max-width: 992px) {
                        .mobile_hideable {
                            display: none;
                        }
                    }

                    @media (max-width: 768px) {
                        font-size: 26px;
                        line-height: 32px;
                    }
                }

                .unlock_hero_points_wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background: #FFF96D;
                    min-height: 42px;
                    width: 100%;
                    gap: 4px;

                    .open_padlock {
                        z-index: 1;
                        width: 54px !important;
                        height: 54px !important;
                        transform: rotate(-8.45deg);
                    }

                    .unlock_hero_points_text {
                        font: normal normal 400 16px/22px 'Roboto';
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.1px;
                        color: #0D1C2B;
                    }


                    .step_number {
                        background: url('../../assets/imgs/bayc/steps_number_container.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        min-width: 32px;
                        min-height: 32px;
                        font: normal normal 400 20px/26px 'Curse Casual';
                        letter-spacing: 0.2px;
                        color: #69341D;
                    }
                }

                .hero_points_earned_ribbon {
                    background: url('../../assets/imgs/bayc/gold_ribbon.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    width: 100%;
                    max-width: 415px;
                    height: 50px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0 30px;
                    margin-bottom: 15px;

                    .hero_points_earned_text {
                        font: normal normal 400 26px/32px 'Curse Casual';
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.2px;
                        color: #FAFAFA;
                        text-shadow: 2px 2px 0px #69341D;
                        -webkit-text-stroke: 1px #69341D;

                        .short_text {
                            display: none;
                        }

                        @media (max-width: 640px) {
                            font-size: 22px;

                            .full_text {
                                display: none;
                            }

                            .short_text {
                                display: block;
                            }
                        }
                    }

                    .hero_points_earned_value_wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 4px;

                        .hero_points_earned_value {
                            font: normal normal 400 26px/32px 'Curse Casual';
                            /* identical to box height, or 123% */
                            display: flex;
                            align-items: center;
                            text-align: right;
                            letter-spacing: 0.2px;
                            color: #FAFAFA;
                            text-shadow: 2px 2px 0px #69341D;
                        }
                    }
                }

                .bayc_earn_status_hero_points_categories {
                    display: flex;
                    // height: 425px;
                    flex-wrap: wrap;
                    position: relative;
                    gap: 40px;
                    justify-content: space-around;
                    align-items: center;

                    .loading_overlay {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: rgba(237, 220, 191, 0.8);
                        z-index: 2;

                        .hero_points_loading {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            position: relative;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            margin-top: 25px;

                            .loading_icon {
                                width: 38px;
                                height: 38px;
                                display: flex;
                                justify-content: center;
                                border-radius: 50%;
                                margin-bottom: 10px;
                            }

                            .loading_text {
                                font: normal normal 400 26px/32px 'Curse Casual';
                                text-align: center;
                                letter-spacing: 0.2px;
                                color: #69341D;
                            }

                        }

                        .error_text {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 25px;
                            font: normal normal 400 26px/32px 'Curse Casual';
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: #69341D;

                        }
                    }

                    .bayc_earn_status_hero_points_card {
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 280px;
                        height: 460px;
                        background-position: center;
                        position: relative;
                        display: flex;
                        gap: 6px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .bayc_earn_status_card_title {
                            font: normal normal 400 26px/32px 'Curse Casual';
                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: #FAFAFA;
                            text-shadow: 2px 2px 0px #69341D;
                            -webkit-text-stroke: 1px #69341D;
                        }

                        .bayc_earn_status_card_requirement {
                            font: normal normal 400 12px/14px 'Roboto';
                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: #69341D;
                        }

                        .highlight_level_not_reached {
                            color: #E0001A;
                        }

                        .bayc_earn_status_reward_text {
                            display: flex;
                            width: 80%;
                            flex-direction: row;
                            font: normal normal 400 14px/16px 'Curse Casual';
                            text-align: center;
                            gap: 5px;
                            letter-spacing: 0.2px;

                            color: #695949;
                        }

                        .bayc_earn_status_reward_text::before,
                        .bayc_earn_status_reward_text::after {
                            content: "";
                            flex: 1 1;
                            border-bottom: 1px solid #695949;
                            padding: 0 10px;
                            margin: auto;
                        }

                        .bayc_earn_status_todo_wrapper {
                            display: flex;
                            width: 80%;
                            justify-content: space-between;
                            align-items: center;
                            height: 25px;
                            font: normal normal 400 10px/14px 'Roboto';
                            letter-spacing: 0.2px;
                            color: #695949;
                        }

                        .bayc_earn_status_finish_game_wrapper {
                            display: flex;
                            width: 80%;
                            justify-content: space-between;
                            align-items: center;

                            .bayc_earn_status_finish_game_text {
                                font: normal normal 400 14px/16px 'Curse Casual';
                                letter-spacing: 0.1px;
                                color: #69341D;
                            }

                            .bayc_earn_status_finish_game_points_wrapper {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 28px;
                                gap: 4px;

                                .bayc_earn_status_finish_point_logo {
                                    display: flex;
                                    height: 20px;
                                    width: 20px;
                                }

                                .bayc_earn_status_finish_points {
                                    font: normal normal 400 16px/18px 'Curse Casual';
                                    letter-spacing: 0.2px;
                                    color: #69341D;
                                }

                                .bayc_earn_status_finish_multiplier {
                                    font: normal normal 700 10px/18px 'Roboto';
                                    text-align: right;
                                    height: 18px;
                                    letter-spacing: 0.1px;
                                    background: #69341D;
                                    border-radius: 4px;
                                    color: #ECBB50;
                                    padding: 2px;
                                }

                                .bayc_earn_status_finish_span {
                                    background: #B99980;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 4px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }

                        .bayc_earn_status_extra_points_wrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #B99980;
                            width: 80%;
                            height: 28px;
                            font: normal normal 400 14px/16px 'Curse Casual';
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: #69341D;
                        }

                        .bayc_earn_status_holding_ape_wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 80%;
                            height: 34px;

                            .bayc_earn_status_holding_ape_text {
                                display: block;
                                justify-content: flex-start;
                                font: normal normal 400 14px/16px 'Curse Casual';
                                letter-spacing: 0.1px;
                                width: 120px;
                                flex-wrap: wrap;
                                color: #69341D;

                                img {
                                    height: 16px;
                                    width: 16px;
                                }
                            }

                            .bayc_earn_status_holding_ape_points_wrapper {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 28px;
                                gap: 4px;

                                .bayc_earn_status_holding_ape_points_logo {
                                    display: flex;
                                    height: 20px;
                                    width: 20px;
                                }

                                .bayc_earn_status_holding_ape_points {
                                    font: normal normal 400 16px/18px 'Curse Casual';
                                    letter-spacing: 0.2px;
                                    color: #69341D;
                                }

                                .bayc_earn_status_holding_ape_multiplier {
                                    font: normal normal 700 10px/18px 'Roboto';
                                    text-align: right;
                                    height: 18px;
                                    letter-spacing: 0.1px;
                                    background: #69341D;
                                    border-radius: 4px;
                                    color: #ECBB50;
                                    padding: 2px;
                                }

                                .bayc_earn_status_holding_ape_span {
                                    background: #B99980;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 4px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }

                        .bayc_earn_status_holding_tower_wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 80%;
                            height: 34px;

                            .bayc_earn_status_holding_tower_text {
                                display: block;
                                justify-content: flex-start;
                                font: normal normal 400 14px/16px 'Curse Casual';
                                letter-spacing: 0.1px;
                                width: 100px;
                                flex-wrap: wrap;
                                color: #69341D;

                                img {
                                    height: 17px;
                                    width: 17px;
                                }
                            }

                            .bayc_earn_status_holding_tower_points_wrapper {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 28px;
                                gap: 4px;

                                .bayc_earn_status_holding_tower_points_logo {
                                    display: flex;
                                    height: 20px;
                                    width: 20px;
                                }

                                .bayc_earn_status_holding_tower_points {
                                    font: normal normal 400 16px/18px 'Curse Casual';
                                    letter-spacing: 0.2px;
                                    color: #69341D;
                                }

                                .bayc_earn_status_holding_tower_multiplier {
                                    font: normal normal 700 10px/18px 'Roboto';
                                    text-align: right;
                                    height: 18px;
                                    letter-spacing: 0.1px;
                                    background: #69341D;
                                    border-radius: 4px;
                                    color: #ECBB50;
                                    padding: 2px;
                                }

                                .bayc_earn_status_holding_tower_span {
                                    background: #B99980;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 4px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }

                        .bayc_earn_status_holding_battle_card_wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 80%;
                            height: 34px;

                            .bayc_earn_status_holding_battle_card_text {
                                display: block;
                                justify-content: flex-start;
                                font: normal normal 400 14px/16px 'Curse Casual';
                                letter-spacing: 0.1px;
                                width: 110px;
                                flex-wrap: wrap;
                                color: #69341D;

                                img {
                                    height: 17px;
                                    width: 17px;
                                    object-fit: contain;
                                }
                            }

                            .bayc_earn_status_holding_battle_card_points_wrapper {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 28px;
                                gap: 4px;

                                .bayc_earn_status_holding_battle_card_points_logo {
                                    display: flex;
                                    height: 20px;
                                    width: 20px;
                                }

                                .bayc_earn_status_holding_battle_card_points {
                                    font: normal normal 400 16px/18px 'Curse Casual';
                                    letter-spacing: 0.2px;
                                    color: #69341D;
                                }

                                .bayc_earn_status_holding_battle_card_multiplier {
                                    font: normal normal 700 10px/18px 'Roboto';
                                    text-align: right;
                                    height: 18px;
                                    letter-spacing: 0.1px;
                                    background: #69341D;
                                    border-radius: 4px;
                                    color: #ECBB50;
                                    padding: 2px;
                                }

                                .bayc_earn_status_holding_battle_card_span {
                                    background: #B99980;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 4px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }

                        .bayc_earn_status_hr_line {
                            margin-top: 20px;
                            border: 1px solid #69341D;
                            height: 1px;
                            width: 85%;
                        }

                        .bayc_earn_status_earn_upto_wrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 32px;
                            font: normal normal 400 14px/16px 'Curse Casual';
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: #69341D;

                            .highlighted_text {
                                font: normal normal 400 26px/32px 'Curse Casual';
                                display: flex;
                                align-items: center;
                                text-align: center;
                                letter-spacing: 0.2px;
                                color: #FFAF00;
                                text-shadow: 1px 1px 0px #69341D;
                                -webkit-text-stroke: 1px #69341D;
                            }
                        }

                        .hero_card_padlock {
                            display: flex;
                            background: url('../../assets/imgs/bayc/card_padlock.png');
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                            bottom: 0px;
                            left: -10px;
                            width: 305px;
                            height: 135px;
                            z-index: 1;
                        }

                        .incomplete_ribbon_on_card {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            background: #E0001A;
                            border: 2px solid #713820;
                            border-radius: 4px;
                            transform: rotate(16.88deg);
                            width: 92px;
                            height: 35px;
                            transform: rotate(16.88deg);
                            z-index: 1;

                            .incomplete_text {
                                font: normal normal 400 18px/22px 'Curse Casual';
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                letter-spacing: 0.2px;
                                color: #FAFAFA;
                                text-shadow: 2px 2px 0px #69341D;
                            }
                        }

                    }

                    .normal_card {
                        background: url('../../assets/imgs/bayc/card_normal_bg.png');
                    }

                    .hard_card {
                        background: url('../../assets/imgs/bayc/card_hard_bg.png');
                    }

                    .crazy_card {
                        background: url('../../assets/imgs/bayc/card_crazy_bg.png');
                    }

                    .nightmare_card {
                        background: url('../../assets/imgs/bayc/card_nightmare_bg.png');
                    }
                }

                .bayc_earn_status_extra_points_desc {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-width: 712px;

                    .bored_ape_loves_tower {
                        width: 340px;
                        height: 240px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }

                        @media (max-width: 768px) {
                            display: none;
                        }
                    }

                    .earn_extra_hero_points {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        padding: 0 12px;

                        @media (max-width: 768px) {
                            align-items: center;
                            text-align: center;
                        }

                        .title_and_image {
                            display: flex;
                            align-items: center;
                        }

                        .bored_ape_loves_tower_small {
                            // width: 130px;
                            height: 112px;
                            display: none;
                            margin-right: 8px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }

                            @media (max-width: 768px) {
                                display: block;
                            }
                        }

                        .earn_extra_hero_points_title {
                            font: normal normal 400 26px/32px 'Curse Casual';
                            display: flex;
                            align-items: center;
                            letter-spacing: 0.2px;
                            color: #69341D;

                            .icon_question_mark {
                                display: flex;
                                width: 22px;
                                height: 22px;
                            }
                        }

                        .earn_extra_hero_points_text {
                            font: normal normal 400 14px/18px 'Roboto';
                            display: flex;
                            align-items: center;
                            letter-spacing: 0.1px;
                            color: #69341D;
                        }

                        .earn_extra_points_button_set {
                            display: flex;
                            gap: 5px;
                            align-items: center;
                            flex-wrap: wrap;

                            @media (max-width: 768px) {
                                justify-content: center;
                            }

                            a {
                                color: initial;
                                text-decoration: none;
                            }

                            .blue_button {
                                width: 114px;
                                height: 40px;
                                background: url('../../assets/imgs/btn_primary.png');
                                background-size: 100% 100%;
                                border: none;
                                font: normal normal normal 14px/18px Curse Casual;
                                color: #F2F2F2;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                img {
                                    width: 14px;
                                    object-fit: contain;
                                    margin: 2px;
                                }
                            }

                            .blue_button:hover {
                                filter: brightness(110%);
                            }
                        }
                    }
                }
            }

            .wallet_verify_card_hold_container {
                z-index: 1;
                display: flex;
                flex-direction: row;
                gap: 24px;
                margin: 33px 9px;

                @media (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    margin: 33px 9px;
                }

                .wallet_verify_card_hold_wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .verify_wallet_address_text {
                        color: #69341D;
                        letter-spacing: 0.1px;
                        font: normal normal 400 14px/18px 'Roboto';

                        @media (max-width: 768px) {
                            text-align: center;
                        }
                    }

                    .wallet_verify_card_hold_content_box {
                        display: flex;
                        flex-direction: column;
                        border: 2px solid #69341D;
                        align-items: center;
                        border-radius: 10px;

                        .wallet_verify_card_hold_top_section {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 15px;

                            .wallet_verify_card_hold_text {
                                color: #69341D;
                                font: normal normal 400 20px/26px 'Curse Casual';
                            }

                            .verified_img {
                                height: 111px;
                                width: 126px;
                            }

                            .no_nft_tower_battle_text {
                                color: #E0001A;
                            }

                            .not_verified {
                                display: flex;

                                .fail_ellipse {
                                    border-radius: 50%;
                                    background: #E0001A;
                                    height: 10px;
                                    width: 10px;
                                    margin: 3px 5px 0 0;
                                }

                                .not_verified_text {
                                    color: #69341D;
                                    letter-spacing: 0.1px;
                                    font: normal normal 700 14px/18px 'Roboto';
                                }
                            }

                            .verified {
                                display: flex;

                                .success_ellipse {
                                    border-radius: 50%;
                                    background: #03BC4D;
                                    height: 10px;
                                    width: 10px;
                                    margin: 3px 5px 0 0;
                                }

                                .verified_text {
                                    color: #69341D;
                                    letter-spacing: 0.1px;
                                    font: normal normal 700 14px/18px 'Roboto';
                                }
                            }

                            .hold_tower_battle_bottom_section {
                                display: flex;
                                flex-direction: column;
                                gap: 15px;
                                align-items: center;
                                justify-content: center;

                                .hold_tower_battle_btn_group {
                                    display: flex;
                                    flex-direction: row;
                                    gap: 5px;

                                    .blue_button {
                                        width: 114px;

                                        img {
                                            width: 14px;
                                            object-fit: contain;
                                            margin: 2px;
                                        }
                                    }

                                    .button_width {
                                        width: 174px;
                                    }
                                }
                            }

                            .blue_button_long {
                                background: url('../../assets/imgs/btn_primary_long.png');
                            }
                        }

                        .blue_button {
                            cursor: pointer;
                            width: 174px;
                            height: 40px;
                            background: url('../../assets/imgs/btn_primary.png');
                            background-size: 100% 100%;
                            border: none;
                            font: normal normal 400 14px/18px 'Curse Casual';
                            color: #F2F2F2;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .blue_button:hover {
                            filter: brightness(110%);
                        }

                        .button_disabled {
                            filter: grayscale(100%);
                            pointer-events: none;
                        }

                        .hold_tower_battle_bottom_text {
                            font: normal normal 400 10px/14px 'Roboto';
                            color: #0D1C2B;
                            padding: 3px 0px;

                            a {
                                text-decoration: none;
                                color: #0D1C2B;
                            }
                        }
                    }

                    .with_padding {
                        padding: 20px 0px;
                    }

                    .padding_applied_on_nft_verified {
                        padding-top: 20px;
                    }

                    .red_border {
                        padding: 20px 0px;
                        border: 2px solid #E0001A;
                        background: rgba(224, 0, 26, 0.1);
                    }

                    .red_border_hold_ticket {
                        padding-top: 20px;
                        border: 2px solid #E0001A;
                        background: rgba(224, 0, 26, 0.1);
                    }
                }
            }
        }
    }
}

.horizontal_separator {
    height: 8px;
    background: #DFDCB8;
    border: 2px solid #2B0E01;
    border-right: none;
    border-left: none;
    box-shadow: 2px 2px 0px #675849;
}

.event_details_section {
    padding: 84px 0;
    background-color: #C3A689;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .ornament_top_left {
        position: absolute;
        top: 5%;
        left: 5%;

        @media (max-width: 768px) {
            max-width: 50px;
            top: 2%;
            left: 5%;
        }
    }

    .ornament_top_right {
        position: absolute;
        top: 5%;
        right: 5%;

        @media (max-width: 768px) {
            max-width: 50px;
            top: 2%;
            right: 5%;
        }
    }

    .ornament_bottom_left {
        position: absolute;
        bottom: 5%;
        left: 5%;

        @media (max-width: 768px) {
            max-width: 50px;
            bottom: 2%;
            left: 5%;
        }
    }

    .ornament_bottom_right {
        position: absolute;
        bottom: 5%;
        right: 5%;

        @media (max-width: 768px) {
            max-width: 50px;
            bottom: 2%;
            right: 5%;
        }
    }

    .event_details_section_title {
        background: url('../../assets/imgs/red_ribbon.png');
        background-size: 100% 100%;
        max-width: 512px;
        width: 100%;
        height: 70px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            width: 90px;
            height: 90px;
            object-fit: contain;
            position: absolute;
            top: -58px;
        }

        .title_text {
            font: normal normal normal 32px/37px 'Curse Casual';
            color: #FFFFFF;
            -webkit-text-stroke: 1px #69341D;
            -webkit-text-fill-color: #ffffff;
            text-shadow: 3px 2px 0px #69341D;
        }
    }

    .event_details_section_description {
        width: 80%;
        text-align: center;
        margin: 24px 0 12px;
        padding: 0 12px;
        font: normal normal normal 16px/22px 'Roboto';
        color: #69341D;

        @media (max-width: 768px) {
            width: 100%;
            padding: 0 16px;
        }
    }

    .view_details_btn {
        z-index: 1;
        cursor: pointer;
        display: flex;
        gap: 30px;
        text-decoration: underline;
        margin-bottom: 30px;

        a {
            color: #69341D;
        }
    }

    .total_rewards_header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95%;

        img {
            width: 100%;
            max-width: 400px;
            height: auto;

            @media (max-width: 1110px) {
                max-width: 300px;
            }

            @media (max-width: 768px) {
                max-width: 200px;
            }

            @media (max-width: 768px) {
                max-width: 100px;
            }
        }

        .total_rewards_header_title {
            font: normal normal normal 26px/32px 'Curse Casual';
            color: #69341D;
            padding: 0 10px;
            text-align: center;
        }

        .desktop_hideable {
            display: none;

            @media (max-width: 992px) {
                display: block;
            }
        }

        .mobile_hideable {
            @media (max-width: 992px) {
                display: none;
            }
        }
    }

    .total_redeemables_body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;

        .total_rewards_contents {
            text-align: center;
            align-items: center;
            font: normal normal normal 14px/18px 'Roboto';
            color: #69341D;
            display: grid;
            grid-template-areas: 'box1 box2 box3 box4 box5''box6 box7 box8 box9 box10';
            grid-gap: 20px;
            margin: auto;
            justify-items: center;
            margin-top: 30px;
            width: 90%;

            @media (max-width: 640px) {
                grid-template-areas: 'box1 box2''box3 box4''box5 box6''box7 box8''box9 box10';
            }

            div>img {
                margin-bottom: 25px;
                width: 100%;
                height: 217px;
                width: auto;

                @media (max-width: 1110px) {
                    height: 180px;
                    width: auto;
                }

                @media (max-width: 768px) {
                    height: 140px;
                    width: auto;
                }

                @media (max-width: 640px) {
                    height: 217px;
                    width: auto;
                }
            }

            .total_rewards_contents_box_title {
                margin-bottom: 10px;
                padding: 0 50px;
            }

            .total_rewards_contents_box1 {
                grid-area: box1;
            }

            .total_rewards_contents_box2 {
                grid-area: box2;
            }

            .total_rewards_contents_box3 {
                grid-area: box3;
            }

            .total_rewards_contents_box4 {
                grid-area: box4;
            }

            .total_rewards_contents_box5 {
                grid-area: box5;
            }

            .total_rewards_contents_box6 {
                grid-area: box6;
            }

            .total_rewards_contents_box7 {
                grid-area: box7;
            }

            .total_rewards_contents_box8 {
                grid-area: box8;
            }

            .total_rewards_contents_box9 {
                grid-area: box9;
            }

            .total_rewards_contents_box10 {
                grid-area: box10;
            }
        }

        .bottom_main_text {
            font: normal normal 400 32px/37px 'Curse Casual';
            color: #69341D;
            letter-spacing: 0.1px;
        }

        .bottom_secondary_text {
            font: normal normal 400 26px/32px 'Curse Casual';
            color: #69341D;
            letter-spacing: 0.1px;
        }

        .long_para_event_details {
            font: normal normal 400 16px/22px 'Roboto';
            color: #69341D;
            letter-spacing: 0.1px;
            width: 80%;

            @media (max-width: 768px) {
                width: 100%;
                padding: 0 16px;
            }
        }
    }
}

.points_earning_steps {
    background-color: #CEB092;
    padding: 84px 0;
    overflow: hidden;
    position: relative;

    .points_earning_steps_title {
        background: url('../../assets/imgs/red_ribbon.png');
        background-size: 100% 100%;
        max-width: 512px;
        width: 100%;
        height: 70px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            width: 90px;
            height: 90px;
            object-fit: contain;
            position: absolute;
            top: -58px;
        }

        .title_text {
            font: normal normal normal 32px/37px Curse Casual;
            color: #FFFFFF;
            -webkit-text-stroke: 1px #69341D;
            -webkit-text-fill-color: #ffffff;
            text-shadow: 3px 2px 0px #69341D;
        }

        @media (max-width: 768px) {
            .title_text {
                font-size: 28px;
                line-height: 28px;
            }
        }
    }

    .points_earning_steps_descriptions {
        text-align: center;
        margin: 24px 0 12px;
        padding: 0 12px;
        font: normal normal normal 16px/22px Roboto;
        color: #69341D;
    }

    .earning_steps {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-items: stretch;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }

        .earning_step {
            width: 240px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            position: relative;
            margin: 24px;

            @media (max-width: 768px) {
                margin: 12px;
            }

            .step_title {
                font: normal normal normal 32px/37px Curse Casual;
                color: #FAFAFA;
                text-shadow: 2px 2px 0px #69341D;
                margin: 24px 0 16px;
            }

            .step_header {
                display: flex;
                flex-direction: column;
                align-items: center;

                .steps_down_arrow {
                    background: url('../../assets/imgs/bayc/steps_down_arrow.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 24px;
                    margin-bottom: 12px;
                    display: none;
                }

                .steps_right_arrow {
                    background: url('../../assets/imgs/bayc/steps_right_arrow.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 110px;
                    height: 24px;
                    position: absolute;
                    top: 12px;
                    right: -64px;
                }

                @media (max-width: 768px) {
                    .steps_down_arrow {
                        display: block;
                    }

                    .steps_right_arrow {
                        display: none;
                    }
                }

                .step_numbering {
                    font: normal normal normal 26px/32px Curse Casual;
                    color: #69341D;
                    margin-bottom: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .step_number {
                        background: url('../../assets/imgs/bayc/steps_number_container.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 46px;
                        height: 46px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .step_title {
                        display: none;
                    }

                    @media (max-width: 768px) {
                        .step_title {
                            display: block;
                            margin: 0;
                            margin-left: 12px;
                            font-size: 30px;
                            line-height: 30px;
                        }
                    }
                }
            }

            .step_contents {
                display: flex;
                flex-direction: column;
                align-items: center;

                .step_image {
                    max-width: 190px;
                    max-height: 120px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .step_description {
                    color: #69341D;
                    font: normal normal normal 14px/18px Roboto;
                    text-align: center;
                    padding: 8px;
                    height: 124px;
                }

                @media (max-width: 768px) {
                    .step_title {
                        display: none;
                    }

                    .step_description {
                        margin: 8px 0;
                    }
                }
            }
        }

        .earning_step:last-child .steps_right_arrow {
            display: none;
        }

        .earning_step:first-child .steps_down_arrow {
            display: none;
        }
    }

    .bored_ape_bg {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .heroes_bg {
        position: absolute;
        top: 0;
        right: 0;

        @media (max-width: 768px) {
            display: none;
        }
    }
}

.step_one_app_links {
    display: flex;

    a {
        width: 119px;
        height: 35px;
        margin: 4px;

        img {
            width: 100%;
        }
    }
}

.step_two_btns,
.step_four_btns {

    a,
    a:hover {
        text-decoration: none;
        color: #F2F2F2;
    }

    .verification_button {
        width: 174px;
        height: 40px;
        background: url('../../assets/imgs/btn_primary_long.png');
        background-size: 100% 100%;
        border: none;
        font: normal normal normal 14px/18px Curse Casual;
        color: #F2F2F2;
    }

    .button_disabled {
        filter: grayscale(100%);
        pointer-events: none;
    }
}

.step_three_nft_market_links {
    display: flex;

    .market_link_button {
        width: 134px;
        height: 40px;
        background: url('../../assets/imgs/btn_primary.png');
        background-size: 100% 100%;
        border: none;
        font: normal normal normal 14px/18px Curse Casual;
        color: #F2F2F2;

        img {
            width: 14px;
            object-fit: contain;
            margin-right: 8px;
        }
    }
}

.step_four_btns {

    .coming_soon_text {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        font: normal normal 400 14px/18px 'Curse Casual';
        letter-spacing: 0.2px;
        color: #69341D;
        margin-top: 4px;
    }
}

.step_two_btns:hover,
.market_link_button:hover {
    filter: brightness(110%);
}