
.container {
    margin-left: var(--sidebar-width);
    padding-right: 65px;

    @media (max-width: 992px) {
        margin-left: 0px;
    }
    @media (max-width: 603px) {
        padding-right: 10px;
    }
}

.sidenav {
    top: 80px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 459px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 16px;
        margin-top: 10px;
    }

    .backBtn {
        background: none;
        border: none;
        outline: none;
        font-weight: 400;
        font-family: 'Curse Casual';
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.2;
        color: #2330D0;
        @media (max-width: 459px) {
            margin-bottom: 13px;
        }

        img {
            margin-right: 16px;
        }

    }

    .transferBtn {
        background: none;
        border: none;
        outline: none;
        width: 184px;
        height: 38px;
        color: #EBEBEB;
        font: normal normal 400 14px/18px 'Curse Casual';
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2330D0;
        border-radius: 4px;

        img {
            margin-right: 11px;
        }
    }
}
.headerRight {
    display: flex;
    align-items: center;
    @media (max-width: 459px) {
        width: 100%;
        justify-content: space-between;

        & .openSeaIcon {
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }
    }

    a.openSeaIcon {
        padding:0px 27px;
    }

    .share_btn {
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 0;
        transition: none;

        &:hover {
            background: none
        }
        &:focus {
            outline: none;
            border: none;
            box-shadow: none;
            background: none;
            transition: none;
        }
    }
}

.dropdown_menu {
    width: 200px;
    min-height: 196px;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);

    .shareLinks {
        list-style: none;
        margin-bottom: 0;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
            min-width: 145px;
            cursor: pointer;
            margin: 13px auto;
            text-align: center;
            @media (max-width: 535px) {
                margin-bottom: 15px;
            }
        
           img {
               margin-right: 12px;
               height: 24px;
               width: 24px;
           }
           a, span {
               font-size: 14px;
               line-height: 17px;
               letter-spacing: 0.1px;
               font-weight: 400;
               font-family: 'Roboto', sans-serif;
               color: #000;
               text-decoration: none;
               display: flex;
               align-items: center;
           }

           .copyToClipboard {
               cursor: pointer;
           }
        }
    }
}


.card__container {
    display: grid;
    grid-template-columns: 400px auto;
    grid-gap: 30px;
    margin-top: 32px;

    @media (max-width: 605px) {
        grid-template-columns: 1fr;
        padding: 0 16px;
    }

    .card__img {
        height: 400px;
        max-width: 400px;
        background: #EBEBEB;
        padding: 32px 62px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 415px) {
            padding: 20px 24px;
        }

        @media (min-width: 415px) and (max-width: 603px) {
            margin: 0 auto;
        }

        @media (max-width: 343px) {
           transform: scale(0.9);
        }

        .img {
            height: 339px;
            width: 276px;
        }
        .polygonIcon {
            position: absolute;
            bottom: 16px;
            left: 16px;
        }
    }
}
.card__details {
    .head {
        margin-bottom: 30px;
        h3 {
            font: normal normal 400 32px/37px 'Curse Casual';
            letter-spacing: 0.1px;
            color: #0D1C2B;
            margin-bottom: 0;
        }
        p{
            font: normal normal 400 16px/24px 'Roboto';
            letter-spacing: 0.1px;
            color: #000;
        }
    }
    .properties {
        margin-bottom: 30px;
        .detail__title {
            font: normal normal 400 26px/32px 'Curse Casual';
            color: #212529;
        }
        .properties__list {
            border: 1px solid #afacac;
            border-radius: 4px;
            padding: 30px;
            display: flex;
            flex-wrap: wrap;

            .property {
                flex: 0 0 33.33%;

                @media (max-width: 1078px) {
                    flex: 0 0 50%;
                }

                &.longProp {
                    @media (max-width: 778px) {
                        min-width: 100%;
                    }
                }

                span {
                    font: normal normal 400 12px/16px 'Roboto';
                    letter-spacing: 0.2px;
                    color: #8A8A8A;
                }
                h6 {
                    font: normal normal 400 20px/30px 'Curse Casual';
                    letter-spacing: 0.2px;
                    color: #0D1C2B;
                }

                .property__item {
                    display: flex;
                    align-items: center;

                    img {
                        width: 52px;
                        height: 48px;
                        object-fit: contain;
                    }

                    .property__value {
                        margin-left: 17px;
                    }
                }
                .attacks {
                    .attacks__value {
                        margin-top: 12px;

                        h6 {
                            margin-bottom: 0px;
                        }
                    } 
                }
            }
        }
    }
}
.token_id {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h4 {
        font-size: 18px;
        font-family: 'Curse Casual';
        margin-bottom: 0;
    }
    span {
        font-size: 14px;
        margin-left: 8px;
        word-break: break-all;
        color: #8A8A8A;

        @media (max-width: 1319px) {
            margin-left: 0;
        }
    }
}