$error-color:#B9000A;


.modalDialog {
    width: 384px;
    min-height: 424px;
    height: fit-content;

    @media (max-width: 330px) {
        max-width: 90%;
    }
    // @media (max-width: 365px) {
    //     height: fit-content;
    // }
}
.modalBody {
    padding: 30px 25px;
    position: relative;
    @media (max-width: 365px) {
        height: fit-content;
        padding: 15px 10px;
    }

    .closeBtn {
        position: absolute;
        right: 10px;
        top: 10px;
        background: none;
        border: none;
        outline: none;
    }
    .sendCard_title {
        font: normal normal 400 20px/30px 'Curse Casual';
        letter-spacing: 0.2px;
        text-align: center;

        margin-top: 16px;

        span {
            text-transform: lowercase;
        }
    }
}
.cardImg {
    width: 140px;
    height: 140px;
    background: #EBEBEB;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .polygonIcon {
        position: absolute;
        left: 4px;
        bottom: 5px;
        height: 26px;
        width: 26px;
    }

    img {
        width: 94px;
        height: 115px;
    }
}

.chestImg {
    display: flex;
    justify-content: center;
    position: relative;
    img {
        height: 140px;
        width: 140px;
    }


    .chest_successIcon {
        position: absolute;
        bottom: -15px;
        right: 26%;
        height: 80px;
        width: 80px;
    }
}

.form_container {
    margin-top: 20px;

    form {
        label {
            font: normal normal 700 14px/18px 'Roboto';
            letter-spacing: 0.1px;
        }
        .input_container {
            position: relative;

            input {
                
                border: 1px solid #CED4DA;
                padding: 10px 14px;
                padding-right: 39px;
                height: 64px;
                width: 100%;
                border-radius: 4px;
                outline: none;

                &:focus {
                    border-color: #6DB5FB;
                }
            }

            img {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                cursor: pointer;
            }
            .available_balance {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .balance {
                    font: normal normal 400 12px/16px 'Roboto';
                    color: #828282;
                    letter-spacing: 0.2px;
                }

                .errorMessage {
                    font: normal normal 400 12px/16px 'Roboto';
                    color: $error-color;
                    letter-spacing: 0.2px;
                }

            }
            .max_notify {
                text-transform: uppercase;
                width: 59px;
                height: 34px;
                position: absolute;
                right: 11px;
                top: 15px;
                font: normal normal 400 16px/24px 'Roboto';
                letter-spacing: 0.1px;
                color: #C7C7C7;
                background: #F2F2F2;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                
            }
            .max {
                background: #DDDFEC;
                color: #231F20;
                
            }
            
            
        }
        
    }
    .arrowIcon {
        margin: 10px;
        display: flex;
        justify-content: center;
    }
    .transferBtn {
        background: none;
        border: none;
        outline: none;
        width: 100%;
        height: 38px;
        color: #EBEBEB;
        font: normal normal 400 14px/18px 'Curse Casual';
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2330D0;
        border-radius: 4px;
        margin-top: 20px;

        img {
            margin-right: 11px;
        }

        &.disabled {
            background: #D6D6D6;
        }
    }
}

.transfer_wrapper {
    width: 100%;

    .transfer_network {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #DADADA;
        padding: 2px 6px;

        .network {
            display: flex;
            align-items: center;
            padding-right: 20px;
            border-right: 1px solid #DADADA;

            img {
                height: 30px;
                width: 30px;
            }
            .network_text {
                margin-left: 8px;

                span {
                    font: normal normal 400 12px/16px 'Roboto';
                    letter-spacing: 0.2px;
                }
                h5 {
                    font: normal normal 700 14px/18px 'Roboto';
                    letter-spacing: 0.1px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.transfer_address {
    display: flex;

    h5 {
        font: normal normal 400 20px/30px 'Curse Casual';
        margin-bottom: 0;
        margin-right: 8px;
    }

    span {
        cursor: pointer;
    }
}

.polyscan_link {
    margin: 20px 0;
    display: flex;
    justify-content: center;

    a {
        font: normal normal 400 14px/18px 'Curse Casual';
        letter-spacing: 0.2px;
        color: #2330D0;
        display: flex;
        align-items: center;
        
        &:hover {
            text-decoration: none;
            color: #2330D0;
        }

        img {
            margin-right: 8px;
            height: 30px;
            width: 30px;
        }
        
    }
}

.address_lg {
    @media screen  and (max-width: 474px){
        display: none;
    }
}
.address_sm {
    display: none;
    @media screen  and (max-width: 474px){
        display: block;
    }
}