.crate_card {
    border-radius: 12px;
    background-color: #0D1C2B;
    color: white;
    height: 100%;
    flex-direction: column;
    display: flex;

    &.legend {
        background-color: white;

        p {
            color: black;
        }
    }

    .top {
        margin-bottom: 10px;

        .img_container {
            width: 80%;
            margin: 0 auto;
            img {
                width: 100%;
                border-radius: 12px 12px 0 0;
            }
        }

        .limits {
            margin: 10px 0;
        }

        p {
            margin-bottom: 0px;
        }
    }

    .bottom_btn {
        margin-top: auto;
        border-radius: 0 0 12px 12px;
    }
}