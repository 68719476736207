.home_container {
    background-image: url('../../assets/imgs/bg-home.jpg');
    width: 100%;
    min-height: 100vh;
    background-attachment: fixed;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgb(249, 249, 249);
    margin-top: #{"max(-135px, -20vh)"};

    .home_content {
        padding-top: #{"max(150px, 15vw)"};
        padding-bottom: 25px;

        @media (min-width: 1000px) {
            padding-top: 150px;
        }
    }

    .title_comment {
        font: normal normal bold 14px/30px Roboto;
        color: #2330D0;

        @media (max-width: 768px) {
            font-size: 12px;
        }
    }

    .title {
        font: normal normal normal 100px/50px Curse Casual;
        color: #0D1C2B;
        line-height: 1;

        @media (max-width: 768px) {
            font-size: 60px;
        }
    }

    .description {
        font: normal normal normal 14px/30px Roboto;
        color: #0D1C2B;
        letter-spacing: 0px;
        text-align: justify;
        line-height: 1.5rem;
    }

    .get_tower_content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .tower_content_texts {
            flex: 0 0 40%;
            width: 75%;

            @media (max-width: 767px) {
                width: 90%;
            }
        }

        .carousel_custom {
            flex: 0 0 540px;
            height: 536px;

            @media (max-width: 1200px) {
                display: none;
            }
        }

        .carousel_right_button {
            cursor: pointer;
            transition-duration: 0.5s;

            @media (max-width: 1200px) {
                display: none;
            }
        }

        @media (max-width: 1200px) {
            flex-direction: column;
        }

        .mobile_earn_swap_tower {
            display: none;

            @media (max-width: 1200px) {
                display: block;
                margin-top: 24px;
            }
            
            .play_to_earn_container {
                margin-top: 25px;
            }
        }

        .swap_container, .play_to_earn_container {
            flex: 0 0 50%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
    
            .swap_text {
                font: normal normal normal 90px/50px Curse Casual;
                color: #F2F2F2;
                text-transform: uppercase;
                transform: rotate(270deg);
                line-height: 1;
                width: 100px;
                white-space: nowrap;
                margin-right: -10px;

                @media (max-width: 450px) {
                    display: none;
                }
            }
        }
    }

    .available_logos_section {
            display: flex;
            font: normal normal normal 20px/30px Curse Casual;
            color: #0D1C2B;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-top: 50px;
            flex-direction: column;

            @media (max-width: 1200px) {
                flex-direction: column;
            }

            .tower_available {
                flex: 1;
                font: normal normal normal 60px/50px Curse Casual;
                margin: 12px;
            }

            .available_on {
                display: flex;
                flex: 3;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: center;
                
                @media (max-width: 993px) {
                    flex-direction: column;
                }
        
                .available_on_logos {
                    display: flex;
                    flex: 2;
                    flex-direction: row;
                    justify-content: space-evenly;
                    flex-wrap: wrap;

                    @media (max-width: 640px) {
                        flex-direction: column;
                        align-items: center;
                    }
        
                    a {
                        width: 200px;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        margin: 10px 20px;
                    }

                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
    }
}