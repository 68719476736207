.bridge_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 190px;
    padding-left: 215px;
    margin: auto;

    @media screen and (max-width: 992px) {
        padding-left: 0;
        flex-direction: column;
        align-items: center;
        max-width: 550px;
        // padding-top: 246px;
    }
    @media screen and (max-width: 580px) {
        max-width: 392px;
    }
    @media screen and (max-width: 400px) {
        max-width: 330px;
    }
    @media screen and (max-width: 340px) {
        max-width: 276px;
    }
}

.container_background {
    background-image: url("../../assets/imgs/bridge/bg-sophia.png");
    background-attachment: fixed;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: #{"max(-150px, -20vh)"};
    background-size: 676px auto;
    height: 100vh;
    
    @media screen and (max-width: 400px) {
        overflow: hidden auto;
    }
}

.bridging_text {
    font-family: Curse Casual;
    font-style: normal;
    font-weight: normal;
    font-size: 90px;
    line-height: 103px;
    text-transform: uppercase;
    color: #F2F2F2;
    transform: rotate(-90deg);
    margin-right: -250px;
    margin-top: 46px;
    height: 397px;

    @media screen and (max-width: 992px) {
        display: none
    }
}

.header_text {
    font-family: Curse Casual;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2px;
}

.header_subtext {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.bridging_box {
    min-width: 550px;
    width: 100%;
    height: 397px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 580px) {
        min-width: 392px;
        
    }
    @media screen and (max-width: 400px) {
        min-width: 330px;
    }
    @media screen and (max-width: 340px) {
        min-width: 276px;
    }
}

.title_section {
    height: 80px;
    background: #000000;
    mix-blend-mode: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 10px;
    width: 100%;
    border-radius: 8px 8px 0 0;
}

.body_section {
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;

    .ticket_image {
        display: flex;
        width: 150px;
        height: 108px;
        margin: auto;
        // border: 1px solid red;
        justify-content: center;
        align-items: center;
    }

    .skin_image {
        display: flex;
        width: 100px;
        height: 100px;
        margin: auto;
        justify-content: center;
        align-items: center;
    }

    .ticket_text, .skin_text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }

    .rarity_image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 13px;
    }
}

.chest_wrapper, .ticket_wrapper, .skin_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .errorMsg {
        span {
            color: #B9000A;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2px;
        }
    }
}
.chest_box, .ticket_box, .skin_box {
    margin-right: 12px;
    width: 100%;

    @media screen and (max-width: 400px) {
        position: relative;
    }
}

.ethereum_box {
    width: 22%;
    color: red;
}

.header_text {
    color: #FFFFFF;
}

.title_text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #0D1C2B;
    letter-spacing: 0.1px;
}

.balance_text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
    letter-spacing: 0.2px;
}

.arrow {
    display: flex;
    justify-content: center;
}

.input_from {
    padding: 10px 14px;
    position: relative;

    font-family: 'Curse Casual';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.1px;
    color: #0D1C2B;
}

.gold_icon {
}

.chest_box, .ticket_box, .skin_box {
    display: flex;
    border: 1px solid #C7CED4;
    height: 64px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 14px;
}

.chest_box:focus-within, .ticket_box:focus-within, .skin_box:focus-within  {
    border: 1px solid #6DB5FB;
}

.chest_box_to, .ticket_box_to, .skin_box_to {
    display: flex;
    align-items: center;
    border: 1px solid #C7CED4;
    height: fit-content;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 14px;
    background: #E5E9EC;
    width: 100%;
    margin-right: 12px;

    // @media screen and (max-width: 580px) {
    //     width: 66%;
    // }
    // @media screen and (max-width: 400px) {
    //     width: 100%;
    // }

}

.input_to {
    padding: 10px 14px;
    height: 64px;
    background: #E5E9EC;
    border: 1px solid #C7CED4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 14px;
}

.to_value {
    font-family: Curse Casual;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.1px;
    color: #0D1C2B;
    margin-left: 12px;
    margin-top: 3px;
    border: none;
    max-width: 100%;
    height: fit-content;
    word-break: break-word;
}

.polygon_box {
    padding: 8px;
    border: 1px solid #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 152px;

    .network_details {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 8px;

        .network {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
        }
    
        .network_name {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            text-align: right;
            letter-spacing: 0.1px;
        }
    }

    .ethereum_network_icon {
        width: 30px;
        height: 30px;

        img {
            width: 100%;
            height: 100%;
            background-color: #151C2F;
            padding: 2px;
            border-radius: 4px;
        }
    }

    .bsc_network_icon {
        width: 30px;
        height: 30px;
        img {
            width: 100%;
            height: 100%;
            background-color: #FFFFFF;
            padding: 2px;
            border-radius: 5px;
            border: 1px solid #EFEFEF;
        }
    }

    .polygon_network_icon {
        width: 30px;
        height: 30px;

        img {
            width: 100%;
            height: 100%;
            background-color: #F1E9FA;
            padding: 2px;
            border-radius: 4px;
        }
    }

    @media screen and (max-width: 594px) {
        width: 192px;
    }
}

.preview_button {
    margin-top: 20px;
    background: #D6D6D6 !important;
    border: none !important;
    font-family: Curse Casual;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    color: #F7F7F7;
    height: 38px;
    cursor: pointer;
}

.max {
    padding: 10px;
    background: #DDDFEC;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #231F20;
    cursor: pointer;

    @media screen and (max-width: 400px) {
        position: absolute;
        bottom: 9px;
        right: 4px;
    }

    @media screen and (max-width: 340px) {
        padding: 4px;
        font-size: 13px;
        bottom: 15px;
    }
}

.input_from {
    border: none;
    padding-left: 10px;
    width: 100%;

    @media screen and (max-width: 340px) {
        &::placeholder {
            font-size: 10px;
        }
    }
}

.input_from::placeholder {
    font-size: 20px;
}

.input_from:focus {
    outline: none;
}

.info_transfer {
    margin-top: 26px;
    padding: 14px;
    background: #F2F2F2;
    border-left: 3px solid #2330D0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #8A8A8A;
    width: 100%;
}

.strongtext {
    font-weight: bold;
    margin-left: 4px;
    margin-right: 4px;
    color: #0D1C2B;
}

.bridge_button {
    margin-top: 20px;
    font-family: Curse Casual;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex !important;
    align-items: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    justify-content: center;
    height: 38px;
    cursor: pointer !important;
    background: #2330D0 !important;
    z-index: 999;
}

.bridge_icon {
    margin-right: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
    -moz-appearance: textfield;
}

.info_icon {
    margin-right: 16px;
}

.backBtn {
    background: none;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    font-weight: 400;
    font-family: 'Curse Casual';
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2;
    color: #2330D0;
    @media (max-width: 459px) {
        margin-bottom: 13px;
    }

    img {
        margin-right: 16px;
    }

}

.back_button_wrapper {
    margin-top: -50px;

    @media screen and (max-width: 992px) {
        margin-top: 0;
        flex: 1;
        width: 100%;
        margin-bottom: 18px;
    }
}

.maxValue {
    margin-left: 5px;
}

.switch_network {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d02323;
    font: normal normal bold 14px/20px Roboto;
    margin-top: 20px;
}
