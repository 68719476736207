.chest_item_container {
    height: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 10px 24px;

    &.polygon_item_container {
        &:not(:last-child) {
            border-bottom: 1px solid #C7C7C7;
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid #DDDFEC;
    }

    

    .image_container {
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    
        img {
            width: 100%;
        }
    }

    .items_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 8px;

        .items {
            display: flex;
            letter-spacing: 0px;
            color: #0D1C2B;
            flex-direction: column;
    
            .title {
                color: #828282;
                font: normal normal bold 14px Roboto;
            }
    
            .amount {
                font: normal normal bold 32px Curse Casual;
            }
        }

        .open_button {
            padding: 8px;
            background-color: #C7C7C7;
            border: 0px;
            border-radius: 4px;
            font: normal normal normal 14px/24px Curse Casual;
            letter-spacing: 0.7px;
            color: #F7F7F7;
            text-align: center;

            &:hover {
                text-decoration: none;
            }
        }

        .address_items {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .ellipsis_parent {
                width: 110px;
            }

            .address {
                font: normal normal normal 14px/20px Roboto;
                color: #0D1C2B;
                opacity: 0.5;
            }

            .copy_icon {
                cursor: pointer;
                color: #2330D0;
            }
        }
    }

    .btn_container {
        display: flex;
        align-items: flex-end;

        @media (max-width: 768px) {
            flex: 1;
            flex-direction: column;
        }

        .btn_icon {
            width: 14px;
            height: 14px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .btn_text {
            margin-left: 5px;
        }

        .primary_btn {
            width: 90px;
            margin-left: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 0.2px;

            @media (max-width: 768px) {
                margin: 4px 0;
            }
            
            .btn_icon {
    
                img {
                    filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(226deg) brightness(115%) contrast(101%);
                }
            }
            &:disabled {
                background: #C7C7C7;
                color: #F7F7F7
            }
        }
    
        .secondary_btn {
            width: 90px;
            margin-left: 5px;
            color: #2330D0;
            background-color: white;
            border: 1px solid #2330D0;
            display: flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 0.2px;
    
            @media (max-width: 768px) {
                margin: 4px 0;
            }

            &.secondary_btn:disabled {
                background: #ffffff;
                border: 1px solid #C7C7C7;
                color: #C7C7C7;
            }
        }
    
        .secondary_btn:hover {
            color: #4953ca;
        }
    }

    .open_count_container {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .count_box {
            background-color: #fff;
            padding: 6px;
            border-radius: 4px;
        }

        .arrow_btn {
            cursor: pointer;
            color: #2330D0;

            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
        }
    }
}

