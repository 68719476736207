.loading_container {
    width: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 130px;
        height: 130px;
    }

    // img {
    //     height: 120px;
    //     width: 120px;
    //     object-fit: contain;
    //     -webkit-animation:spin 1s linear infinite;
    //     -moz-animation:spin 1s linear infinite;
    //     animation:spin 1s linear infinite;
  
    // }
    
    // @-moz-keyframes spin { 
    //     100%  { -moz-transform: rotate(360deg); } 
    // }
    // @-webkit-keyframes spin  { 
    //     100%  { -webkit-transform: rotate(360deg); } 
    // }
    // @keyframes spin  { 
    //     100%  { -webkit-transform: rotate(360deg); } 
    // }

}