body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn.btn-cdh-primary {
  background-color: #2330D0;
  color: white;
  border-radius: 2rem;
  padding: 0.75rem 2rem;

  &.btn-sm {
    padding: 0.35rem 1rem;
  }

  &:hover {
    color: white;
    background-color: darken(#2330D0, 10%);
  }
}

.btn.btn-cdh-light {
  background-color: white;
  color: #2330D0;
  border-radius: 2rem;
  padding: 0.75rem 2rem;
}

.btn.btn-cdh-outline {
  color: white;
  border: 1px solid white;
  padding: 0.75rem 2rem;
  border-radius: 2rem;

  &.active {
    background-color: #2330D0;
    border-color: #2330D0;
  }

  &:hover {
    color: white;
    background-color: #2330D0;
    border-color: #2330D0;
  }
}

.btn.btn-cdh-dropdown {
  color: white;
  border-color: white;
  width: 100%;
  border-radius: 40px;

  &:hover {
    color: white;
    border-color: #2330D0;
  }
}

p {
  color: white;
}

.modal-dialog {
  .modal-header {
    border: none;
  }

  .modal-footer {
    border: none;
  }
}

.btn.btn-cdh-general-blue {
  background-color: #2330D0;
  border-radius: 4px;
  color: #FFF;
  text-align: center;
  font: normal normal normal 14px/12px Curse Casual;
  padding: 12px;

  &:hover {
    color: #FFF;
  }

  &:disabled {
    background-color: #C7C7C7;
    color: #F7F7F7;
  }
}

.btn.btn-cdh-general-blue-dbrown {
  background-color: #2330D0;
  border-radius: 4px;
  color: #FFF;
  text-align: center;
  font: normal normal normal 14px/12px Curse Casual;
  padding: 12px;

  &:hover {
    color: #FFF;
  }

  &:disabled {
    background-color: #C3A689;
    color: #D6C5B4;
  }
}

.btn.btn-cdh-general-white {
  background-color: #FFF;
  color: #2330D0;
  border: 1px solid #2330D0;
  border-radius: 4px;
  text-align: center;
  font: normal normal normal 14px/12px Curse Casual;
  padding: 12px;

  &:hover {
    color: #2330D0;
  }
}

.margin-escape {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.web3modal-modal-lightbox {
  z-index: 999 !important;
}