.cdh_card {
    border-radius: 8px;
    background-color: black;
    color: white;    
    flex-direction: column;
    display: flex;
    min-width: 150px;
    max-width: 200px;
    margin:10px;
    min-height: 200px;
    box-shadow: 3px 3px 3px #06101a;

    @media (max-width: 768px) {
        min-width: 100px;
        max-width: 150px;
    }

    .top{
        text-align: center;
        img{
            max-height:200px;
            @media (max-width: 768px) {
               max-width: 100px;
            }
        }
    }
    .bottom{
        color:white;
        background-color: #0B1B2A;
        
        padding:8px;
        .name{
            font-weight: bold;
        }
        .address_container{
            width: 100%;
            display:flex;
            padding-top:10px;
            align-items: center;
            .address{
                max-width: 90%;
                font-size: 14px;
                font-weight: lighter;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-right: 4px;
            }
            #copy-icon{
                float:right;
            }
        }
       
    }
}