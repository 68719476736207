#faq_container {
    background-color: #0d1c2b;
    text-align: center;
    padding-bottom: 10rem;

    .title_top {
        color: #a0a0a0;
        margin-bottom: 0;
    }

    h2 {
        display: inline-block;
        font-size: 3rem;
        margin-bottom: 0;
        border-bottom: 4px solid #00f;
    }

    .tab_box {
        margin-top: 2rem;
        padding: 0 5vw;

        button {
            width: 100%;
            padding-top: .5rem;
            padding-bottom: .5rem;
            background-color: #060f18;
            border-color: #060f18;
            margin-bottom: .5rem;

            &:hover, &:active {
                background-color: #2330d0;
                border-color: #2330d0;
            }
        }
    }

    .content_box {
        margin: 3rem auto 3rem auto;

        :global .accordion {
            .card {
                color: #fff;
                background-color: #0b1825;
                border-radius: 5px;
                margin-bottom: 1rem;
                text-align: left;

                .card-header {
                    svg {
                        float: right;
                    }

                    &.accordion_open {
                        font-weight: 500;

                        svg {
                            color: #2330d0;
                        }
                    }
                }

                .card-body {
                    font-weight: 300;
                    font-size: .9rem;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    #faq_container {
        background-color: #0d1c2b;
        text-align: center;
        padding-bottom: 10rem;

        .title_top {
            color: #a0a0a0;
            margin-bottom: 0;
        }

        h2 {
            font-size: 3rem;
            margin-bottom: 0;
        }

        .title_underbar {
            border-top: 4px solid #00f;
            width: 200px;
            margin: 0 auto;
        }

        .tab_box {
            margin-top: 2rem;

            button {
                width: 100%;
                padding-top: 1rem;
                padding-bottom: 1rem;
                background-color: #060f18;
                border-color: #060f18;

                &:hover, &:active {
                    background-color: #2330d0;
                    border-color: #2330d0;
                }
            }
        }

        .content_box {
            width: 90vw;
            margin: 3rem auto 3rem auto;

            :global .accordion {
                .card {
                    color: #fff;
                    background-color: #0b1825;
                    border-radius: 5px;
                    margin-bottom: 1rem;
                    text-align: left;

                    .card-header {
                        svg {
                            float: right;
                        }

                        &.accordion_open {
                            font-weight: 500;

                            svg {
                                color: #2330d0;
                            }
                        }
                    }

                    .card-body {
                        font-weight: 300;
                        font-size: .9rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    #faq_container {
        background-color: #0d1c2b;
        text-align: center;
        padding-bottom: 10rem;

        .title_top {
            color: #a0a0a0;
            margin-bottom: 0;
        }

        h2 {
            font-size: 3rem;
            margin-bottom: 0;
        }

        .title_underbar {
            border-top: 4px solid #00f;
            width: 200px;
            margin: 0 auto;
        }

        .tab_box {
            margin-top: 2rem;

            button {
                width: 100%;
                padding-top: 1rem;
                padding-bottom: 1rem;
                background-color: #060f18;
                border-color: #060f18;

                &:hover, &:active {
                    background-color: #2330d0;
                    border-color: #2330d0;
                }
            }
        }

        .content_box {
            width: 90vw;
            margin: 3rem auto 3rem auto;

            :global .accordion {
                .card {
                    color: #fff;
                    background-color: #0b1825;
                    border-radius: 5px;
                    margin-bottom: 1rem;
                    text-align: left;

                    .card-header {
                        cursor: pointer;
                        
                        svg {
                            float: right;
                        }

                        &.accordion_open {
                            font-weight: 500;

                            svg {
                                color: #2330d0;
                            }
                        }
                    }

                    .card-body {
                        font-weight: 300;
                        font-size: .9rem;
                    }
                }
            }
        }
    }
}