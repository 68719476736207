.curr-lang img {
    margin-right: 8px;
    filter: saturate(0) invert(1) brightness(100);
}

.curr-lang {
    color: #fff;
    font: normal normal 400 20px/30px Curse Casual;
    letter-spacing: 0.56px;
    margin-bottom: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
        font-size: 20px;
        margin-left: 0px;
    }
}

#lang-switch {
    color: #0D1C2B;
    border-radius: 6px;
    max-width: 350px;

    .modal-footer {
        border: none;
    }
}

#lang-switch .select-lang {
    color: #0D1C2B;
    margin-bottom: 10px;
}

#lang-switch .list-group-item svg {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -7.5px;
}

#lang-switch .list-group-item {
    border-top: none;
    border-bottom: 1px solid #89898E;
    padding: 1rem 0;
    margin: 0;
    cursor: pointer;
}

#lang-switch .list-group-item.active {
    font-weight: bold;
    background-color: #FFF;
    color: #0D1C2B;
}

#lang-switch .list-group-item.active img{
    margin-left: 8px;
    margin-top: 2px;
}

#lang-switch .modal-footer span {
    margin: auto;
    cursor: pointer;
    color: #89898E;
}

#lang-switch .cancel_button {
    width: 100px;
}
