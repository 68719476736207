.bridge_modal {
    position: absolute;
    display: flex;
    
    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 3px;
        right: 12px;
        color: #FFFFFF;
        cursor: pointer;
        z-index: 1;
    }

    .close_btn_chest {
        color: #2330D0;
    }

    .bridge_modal_header {
        position: absolute;
        height: 136px;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #000000;
        justify-content: center;
        align-items: center;
        border-radius: 5px 5px 0px 0px;

        .header_texts {
            padding-top: 24px;
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #FFFFFF;
        }

        .header_texts_chest {
            color: #333333;
        }

        .progress_bar {
            width: 250px;
            margin-top: 16px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .bridge_modal_header_chest {
        background: #FFFFFF;
    }

    .bridge_modal_body {
        position: relative;
        margin-top: 136px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        width: 100%;
        padding: 0.5rem;

        .transfer_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 5px;
            align-items: center;
        }

        .ethereum_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }

        .polygon_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }

        .ethereumIcon {
            width: 40px;
            height: 40px;
            background-color: #151C2F;
            padding: 2px;
            border-radius: 4px;
        }

        .polygonIcon {
            width: 40px;
            height: 40px;
            background-color: #F1E9FA;
            padding: 2px;
            border-radius: 4px;
        }

        .modeText {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #828282;
        }

        .trasferArrow {
            height: 25px;
        }

        .info_icon {
            margin-right: 5px;
        }

        .processing {
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #333333;
            margin-top: 16px;
        }

        .processing_msg {
            display: flex;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.1px;
            color: #333333;
            margin-top: 15px;
        }

        .canceled {
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #333333;
        }

        .txn_failed_img {
            display: flex;
            justify-content: center;
        }

        .failed {
            font-family: 'Curse Casual';
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #333333;
            margin: 10px auto;
        }

        .ok_button {
            width: 150px;
            margin-top: 20px;
            justify-content: center;
            align-items: center;
            padding: 10px 88px;
            background: #2330D0;
            border-radius: 4px;

            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: #F2F2F2;
            cursor: pointer;
        }

        .view {
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            letter-spacing: 0.2px;
            color: #2330D0;
            margin-left: 12px;
        }

        .bridge_spinner {
            color: #BCC6FC;
            height: 120px;
            width: 120px;
        }

        .etherScan, .bscScan {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            margin-top: 25px;
            margin-bottom: 10px;
        }

        .timing_info {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.1px;
            color: #333333;
            margin-top: 20px;
        }

        .special_text {
            font-weight: bold;
        }

        .success_info {
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #333333;
            margin-top: 20px;
        }

        .hashWrapper {
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            align-items: center;
        }
        
        .titleHash {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1px;
            color: #828282;
            white-space: nowrap;
        }
        
        .valueHash {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.1px;
            color: #0D1C2B;
            word-break: break-word;
            margin-left: 5px;
        }

        .etherRedirect, .bscRedirect {
            display: flex;
        }

        .disableButton {
            pointer-events: none;
        }

        .success_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .success_icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 89px;
                height: 89px;
                margin-bottom: 19px;
            }

            .success_text {
                width: 320px;
                font-family: 'Curse Casual';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                letter-spacing: 0.2px;
                color: #333333;
                margin-bottom: 25px;
            }

            .success_item_type_count {
                width: 90%;
                padding: 6px;
                border-top: 2px solid #D6D6D6;
                border-bottom: 2px solid #D6D6D6;
                
                .success_item_type_count_content {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .item_image {
                        display: flex;
                        width: 62px;
                        height: 45px;
                        margin-right: 10px;
                        flex-direction:row;
                        justify-content: center;
                        align-items: center;
                    }

                    .item_image_skin {
                        width: 56px;
                        height: 56px;
                    }

                    .item_type {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        margin-right: 40px;
                        font-size: 12px;
                        line-height: 14px;
                        letter-spacing: 0.2px;
                        color: #828282;
                    }

                    .item_count {
                        font-family: 'Curse Casual';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 26px;
                        line-height: 32px;
                        letter-spacing: 0.2px;
                        color: #333333;
                    }
                }
            }

            .polygonScan {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                margin-top: 10px;
                margin-bottom: 10px;

                .polygonRedirect {
                    display: flex;
                    flex-direction: row;

                    .polygonScanView {
                        font-family: Curse Casual;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.2px;
                        color: #2330D0;
                    }
                }

                .polygonLinkDisable {
                    pointer-events: none;
                }
            }
        }
    }
}