.footerFull {
    position: relative;
    z-index: 4;
    min-height: 290px;
    overflow: hidden;
}

.footerbg {
    background-color: #000;
    width: 100%;
    color: #fff;
    min-height: 160px;
    height: 100%;
    display: flex;

    @media (max-width: 992px) {
        min-height: 450px;
    }

    .footer_container {
        display: flex;
        padding: 0 80px;

        @media (max-width: 992px) {
            padding: 0;
        }

        .data {
            @media (max-width: 992px) {
                flex: 1;
            }

            .language_selector_container {
                display: flex;
                justify-content: flex-start;
                margin-top: 16px;

                @media (max-width:992px) {
                    justify-content: space-evenly;
                }

                .language_selector {
                    background-color: #2330D0;
                    border-radius: 4px;
                }
            }

            .social_links_container {
                margin-top: 16px;

                @media (max-width: 992px) {
                    display: none;
                }
            }

            .mobile_social_links_container {
                margin-top: 16px;

                @media (min-width: 992px) {
                    display: none;
                }

                .horizontal_line {
                    height: 1px;
                    background-color: #454545;
                    margin: 12px 0;
                }
            }
        }
    }

    .links {
        font: normal normal normal 14px/24px Curse Casual;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        margin-top: 48px;
        display: flex;
      
        @media (max-width: 992px) {
            justify-content: space-evenly;
        }

        @media (max-width: 1180px) {
            flex-direction: column;
            align-items: center;
            margin-top: 24px;

            a {
                margin: 4px;
            }
        }

        a {
            margin-right: 16px;
            color: #f7f7f7;
            cursor: pointer;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .copyright_section {
        margin-top: 12px;
        font: normal normal normal 10px/20px Roboto;
        color: #89898e;
        display: flex;
        align-items: center;

        img {
            margin-right: 12px;
            width: 84px;
            height: 50px;
        }

        @media (max-width: 1200px) {
            flex-direction: column;
            margin: 12px 0;

            .copyright_text {
                margin-top: 12px;
            }
        }
    }

    .footer_newsletter {
        position: relative;
        top: 12px;

        @media (max-width: 992px) {
            display: none;
        }
    }
}

.footer_links_container {
    background-color: #000;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 992px) {
        flex-direction: column;
    }

    .scroll_to_top_container {
        width: 60px;
        height: 60px;
        border-radius: 8px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        background-color: #2330d0;

        @media (max-width: 992px) {
            width: 100%;
            height: 70px;
            justify-content: flex-end;
            padding-right: 24px;
            border-radius: 0;
        }
    }
}
