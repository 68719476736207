.discord_container {
  background-image: url('../../assets/imgs/home/bg-wooden-board-2.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 190px;
  width: 300px;

  position: fixed;
  right: 40px;
  bottom: -12px;
  z-index: 100;

  a, a:hover {
    text-decoration: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;

    .title {
      font: normal normal normal 14px/20px Roboto;
      color: #E1C8AF;
    }

    .store_container {
      display: flex;

      .app_qr {
        width: 98px;
        height: 98px;
      }

      .store_btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a {
          width: 119px;
          height: 35px;
          margin: 0 8px 4px 8px;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
}
