.login_page_container {
    position: fixed;
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background: rgba(29, 10, 2, 0.75);
    z-index: 99;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .login_page_backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(29, 10, 2, 0.75);
    }

    .login_page {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media (max-width: 992px) {
        .login_page {
            max-width: 640px;
            width: 100%;
        }
    }

    // @media (max-width: 640px) {
    //     .login_page {
    //         top: 25%;
    //     }
    // }
}

.displayNone {
    display: none;
}

.bind_login {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    @media (min-width: 768px) {
        width: 640px;
    }

    @media (max-width: 768px) {
        background-image: url('../../assets/imgs/bind/bg-m-paper-short.png');
    }

    .page_link_container {
        display: flex;
        justify-content: flex-end;
        margin: 12px 0;

        .section_link {
            font: normal bold normal 14px/17px Roboto;
            color: #69341D;
            text-align: right;
            letter-spacing: 0.1px;
            text-decoration-line: underline;
            cursor: pointer;
            margin: 0 8px;
            display: flex;
            align-items: center;
        }

        .daily_rewards_button {
            background-image: url('../../assets/imgs/bg-daily-reward-button.png');
            width: 273px;
            height: 50px;
            cursor: pointer;

            .button_text {
                font: normal normal normal 20px/30px Curse Casual;
                color: #FFFFFF;
                position: relative;
                top: 10px;
                left: 75px;
            }
        }
    }

    .bind_container_items {
        display: flex;
        flex-direction: column;
        padding: 32px;
        height: 100%;

        @media (max-width: 768px) {
            padding: 12px;
            align-items: center;
        }

        .bind_texts {
            padding: 0px 16px;
            width: 87%;
            margin: 8px 0;

            @media (max-width: 768px) {
                width: 100%;
            }

            .coming_soon_title {
                @media (max-width: 768px) {
                    line-height: 0.8;
                    margin-top: 1em;
                    width: 80%;
                }
            }

            .coming_soon_subtitle {
                @media (max-width: 768px) {
                    line-height: 1;
                    margin-top: 0.75em;
                    width: 80%;
                }
            }

            .coming_soon_description {
                @media (max-width: 768px) {
                    margin-top: 0.75em;
                    width: 100%;
                }
            }
        }
    }

    .bind_description {
        margin-top: 0.5em;
        line-height: 1.35;
    }

    .avatar_image {
        position: absolute;
        top: 0;
        right: -125px;

        @media (max-width: 768px) {
            right: 0;
        }

        img {
            width: 250px;
            object-fit: contain;

            @media (max-width: 768px) {
                max-width: 130px;
            }
        }
    }
}

.section_container {
    position: relative;

    .claim_box_container {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        max-width: 924px;
        min-height: 325px;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        position: relative;
        background-image: url('../../assets/imgs/bind/bg-i-paper.png');

        @media (min-width: 992px) {
            background-image: url('../../assets/imgs/bind/bg-d-paper-long.png');
        }

        @media (max-width: 768px) {
            background-image: url('../../assets/imgs/bind/bg-m-paper-long.png');
        }

        @media (max-width: 450px) {
            padding: 30px 24px 24px 20px;
        }

        .bind_box_divider_parent {
            display: flex;
            justify-content: center;

            .bind_box_divider {
                width: 95%;

                img {
                    width: 100%;
                }
            }
        }

        .claim_box_header_container {
            position: absolute;
            top: -5%;
            left: -4%;

            @media (max-width: 991px) {
                top: -3%;
            }

            @media (max-width: 768px) {
                top: -4%;
            }

            .claim_box_header_img {
                position: absolute;
                top: -20%;

                @media (max-width: 768px) {
                    img {
                        width: 80%;
                    }
                }
            }

            .claim_box_header_bar {
                background-image: url('../../assets/imgs/bind/img-title-bar.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                width: 382px;
                height: 63px;
                color: #FFFFFF;
                font: normal normal normal 32px Curse Casual;
                padding: 6px 40px 15px 8%;
                margin-left: 60px;
                white-space: nowrap;

                @media (max-width: 768px) {
                    font: normal normal normal 20px Curse Casual;
                    padding-top: 12px;
                    max-width: 280px;
                    height: 55px;
                    margin-left: 50px;
                }
            }
        }

        .header {
            display: flex;
            justify-content: space-evenly;

            @media (max-width: 991px) {
                flex-direction: column;
            }

            .header_texts {
                flex: 1;
                padding-top: 24px;
                padding-left: 16px;

                @media (max-width: 991px) {
                    padding-top: 8px;
                    width: 80%;
                }

                @media (max-width: 450px) {
                    padding-left: 6px;
                }

                .header_subtitle {
                    font: normal normal normal 32px Curse Casual;

                    @media (max-width: 768px) {
                        font: normal normal normal 26px Curse Casual;
                    }
                }

                .header_description {
                    margin-top: 4px;
                    display: block;
                    line-height: 1.5;
                    font: normal normal normal 14px Roboto;

                    @media (max-width: 768px) {
                        font: normal normal normal 12px Roboto;
                    }
                }
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12px 18px;
            position: relative;

            @media (max-width: 768px) {
                padding: 12px 8px;
            }

            .bind_section {
                display: flex;
                flex-direction: column;
                width: 100%;

                .section_title {
                    font: normal normal normal 32px Curse Casual;
                    color: #69341D;
                    display: flex;
                    justify-content: center;
                }

                .bind_section_title {
                    font: normal normal normal 32px Curse Casual;
                    color: #69341D;

                    @media (min-width: 768px) {
                        font: normal normal normal 50px Curse Casual;
                    }
                }

                .bind_descriptions {
                    width: 80%;
                    margin: 20px 0;
                    display: block;
                    line-height: 1.5;
                    font: normal normal normal 14px Roboto;

                    @media (max-width: 768px) {
                        width: 70%;
                        font: normal normal normal 12px Roboto;
                    }
                }

                .details {
                    display: flex;
                    font: normal normal normal 20px Curse Casual;
                    align-items: center;

                    @media (max-width: 768px) {
                        flex-direction: column;
                    }

                    .cdh_logo {
                        margin-top: 32px;

                        @media (max-width: 768px) {
                            margin-top: 0;

                        }
                    }

                    .user_info {
                        .bind_texts {
                            margin: 5px 0;
                            width: 100%;
                            color: #9C7D5D;

                            @media (max-width: 768px) {
                                width: 100%;
                            }
                        }

                        .item_info {
                            display: flex;
                            align-items: center;
                            background-color: #E5CCAF;
                            padding: 5px;
                            flex: 1;
                            margin: 5px 5px 5px 0;

                            .item_img {
                                width: 30px;
                                height: 30px;
                                margin-right: 8px;
                                padding: 1px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .level_gems {
                            display: flex;

                            @media (max-width: 768px) {
                                flex-direction: column;
                            }
                        }
                    }
                }

                .logout_button_container {
                    margin-top: 4px;
                    display: flex;
                    justify-content: center;

                    .logout_button {
                        background-color: transparent;
                        margin-top: 8px;
                        width: 30%;
                    }
                }
            }

            .steps_button_section {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;

                @media (min-width: 992px) {
                    flex-direction: column;
                }

                .bind_steps {
                    display: flex;
                    margin: 20px 20px 20px 0px;

                    svg {
                        font-size: 26px;
                    }

                    @media (max-width: 991px) {
                        flex-direction: column;
                        align-items: center;
                    }

                    .bind_step {
                        width: 35px;
                        height: 35px;
                        text-align: center;
                        background-color: #C3A689;
                        border-radius: 24px;
                        font: normal normal normal 32px Curse Casual;
                        color: #9C7D5D;

                        &.active {
                            background-color: #69341D;
                            color: #FFFFFF;
                        }
                    }

                    .divider {
                        width: 180px;
                        height: 1px;
                        margin: 16px 2px;
                        border: 1px solid #69341D;

                        @media (max-width: 991px) {
                            width: 1px;
                            height: 50px;
                            margin: 0px 2px;
                        }

                        @media (max-width: 768px) {
                            width: 1px;
                            height: 35px;
                            margin: 0px 2px;
                        }
                    }
                }

                .bind_buttons {

                    @media (max-width: 991px) {
                        flex-direction: column;
                        align-items: center;
                        width: 60%;
                    }

                    @media (max-width: 768px) {
                        width: 100%;
                    }

                    > :nth-child(2) {
                        @media (max-width: 991px) {
                            margin: 35px 10px;
                        }

                        @media (max-width: 768px) {
                            margin: 18px 10px;
                        }
                    }

                    .step_button {
                        width: 100%;
                        height: 50px;
                        min-width: 202px;
                        max-width: 300px;
                        margin: auto;

                        @media (min-width: 992px) {
                            margin-right: 15px;
                        }

                        button {
                            width: 100%;
                            height: 50px;
                        }
                    }

                    .step_mini_container {
                        display: flex;
                        color: #6C3D0A;
                        font: normal normal normal 20px Curse Casual;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid #C3A689;
                        border-radius: 4px;
                        padding: 4px 10px;
                        min-height: 50px;

                        svg {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.earn_tower_details {
    flex: 1;
    width: 100%;
    background-color: #3C1E11;
    border-radius: 15px;
    padding: 12px;
    margin-top: 1.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    max-height: 70px;
    font: 400 normal normal 14px Curse Casual;

    @media (max-width: 768px) {
        margin-top: 0px;
        max-height: 135px;
    }

    &.small_container {
        @media (max-width: 768px) {
            width: 92%;
            max-height: 90px;
            margin-bottom: 16px;
            margin-left: 12px;
        }
    }

    .right_panel {
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .cdh_logo {
        width: 100px;
        height: 50px;
        margin-right: 6px;

        @media (max-width: 768px) {
            width: 50%;
            height: 80px;
        }
    }

    .stores_logo {
        margin-left: 10px;

        @media (max-width: 768px) {
            width: 100%;
            margin-top: 5px;
            margin-left: -22px;
        }

        img {
            width: 30px;
            height: 30px;
            margin-left: 8px;
        }
    }
}

.login_modal {
    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 3px;
        right: 12px;
        color: #2330D0;
        cursor: pointer;
        z-index: 1;
    }

    .login_modal_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;

        .header_texts {
            text-align: center;
            font: normal normal normal 20px/30px Curse Casual;
            letter-spacing: 0px;
            color: #0D1C2B;
            padding-top: 24px;
        }
    }
}

.link_error_modal {
    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 3px;
        right: 12px;
        color: #2330D0;
        cursor: pointer;
        z-index: 1;
    }

    .link_error_modal_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;

        .header_texts {
            font: normal normal normal 32px Curse Casual;
            color: #2330D0;
            text-align: center;
            letter-spacing: 0px;
            padding-top: 24px;
        }

        .sub_texts {
            font: normal normal normal 20px Curse Casual;
            text-align: center;
            letter-spacing: 0px;
            padding: 10px 0;
        }

        .link_text {
            font-weight: 600;
        }

        .submit_btn {
            background-color: #2330D0;
            color: white;
            padding: 10px 80px;
            margin-top: 30px;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}