
.ChestSale {
    display: flex;
    justify-content: space-around;
    padding-top: 150px;
    padding-bottom: 60px;
    padding-left: var(--sidebar-width);

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
    }

    .LeftBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 550px;
        width: 100%;
        margin-right: 50px;

        @media (max-width: 1200px) {
            margin-right: 0px;
        }

        // Workaround for boostrap carousel fade transitions broken when no background in carousel item
        // More info here: https://github.com/twbs/bootstrap/issues/28599#issuecomment-480489915
        .carousel-fade .active.carousel-item-left,
        .carousel-fade .active.carousel-item-right {
            transition: .6s opacity ease;
        }

        .ChestCarousel {
            width: 350px;
            margin-right: 40px;
            margin-left: 40px;
        }
        
        .ArrowBtn {
            width: 60px;
            background-color: #ffffff80;
            border-radius: 100%;
            
            @media (max-width: 550px) {
                width: 12vw;
            }
        }

        .ChestImg {
            width: 100%;
        }
    }
    
    .RightBox {

        label{
            margin-bottom: 0px;
        }

        background-image: url('../../assets/imgs/bg-wooden-board.png');
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        background-size: 100% 100%;
        max-width: 450px;
        min-height: 550px;
        padding-top: 70px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 80px;

        @media (max-width: 550px) {
            padding-left: 10vw;
            padding-right: 10vw;
        }

        .TopSection {
            display: flex;
            flex-direction: column;
            min-width: 80%;

            .btn-group {
                .btn-cdh-new {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #2330D0;
                    border-radius: 4px;
                    text-align: center;
                    font: normal normal normal 14px/12px Curse Casual;
                    margin-left: 4px;
                    margin-right: 4px;
                    box-shadow: none;

                    &.active {
                        background-color: #2330D0;
                        border-color: #2330D0;
                        color: white;
                    }

                    &:hover {
                        border: 2px solid white;
                        border-color: #2330D0;
                    }
                }
            }

            .ChestSelector {
                margin-bottom: 13px;
                .btn {
                    height: 48px;
                    width: 80px;
                }
            }

            .ChestInfoHeader {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font: normal normal normal 32px/37px Curse Casual;
                color: #0d1c2b;

                .ChestStock {
                    display: flex;
                    align-items: center;
                    font-size: 24px;
                    line-height: 28px;

                    > .Supply {
                        padding-top: 4px;
                        font-size: 16px;
                        line-height: 18px;
                    }
                }
            }

            .ChestDescription {
                color: #0d1c2b;
                font: normal normal normal 14px/20px Roboto;
                margin-bottom: 0px;
            }

            .QuantitySelector {
                display: flex;
                flex-direction: column;
                margin-top: 11px;
                margin-bottom: 7px;
                font: normal normal bold 14px/19px Roboto;
                color: #0D1C2B;

                .quantitySection {
                    display: flex;
                    align-items: center;

                    .QuantityInput {
                        margin-left: 16px;
                        width: 100%;

                        input {
                            display: block;
                            width: 100%;
                            padding: 0.375rem 0.75rem;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #495057;
                            background-color: #fff;
                            background-clip: padding-box;
                            border: 1px solid #ced4da;
                            border-radius: 0.25rem;
                        }

                        input:focus {
                            outline:  1px solid #abc1d6;
                        }
                    }
                }
                
                .chest_input_error {
                    color: #d02323;
                    font: normal normal bold 14px/20px Roboto;
                    margin: 4px auto;
                }

                .btn {
                    height: 48px;
                    width: 48px;
                    font-size: 20px;
                }
            }

            .TotalPriceBox {
                margin-top: 7px;
                margin-bottom: 7px;
                background-color: #ffffff;
                padding-left: 14px;
                padding-right: 24px;
                padding-top: 9px;
                padding-bottom: 9px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 48px;
                font: normal normal bold 14px/19px Roboto;
                color: #0D1C2B;

                .PriceHolder {
                    display: flex;
                   
                    .TowerIcon {
                        width: 30px;
                        height: 30px;
                        margin-right: 8px;
                    }

                    .Price {
                        padding-top: 3px;
                        font: normal normal normal 20px/23px Curse Casual;
                        color: #0D1C2B;
                    }
                }
            }
        }

        .LoadingWheel {
            width: 20px;
            height: 20px;
            margin-top: -5px;
            margin-bottom: -5px;
        }

        .NotEnoughTowerSection {
            display: flex;
            flex-direction: column;
            align-items: center;

            .NotEnoughTowerLabel {
                color: #d02323;
                font: normal normal bold 14px/20px Roboto;
                margin-bottom: 14px;
            }

            a {
                color: #fff;
                text-decoration: none;
                width: 100%;
            }
        }
    }
}
