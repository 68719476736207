
// .spinner {
    
//     color: #BCC6FC !important;
//     height: 120px !important;
//     width: 120px !important;

// }

.bridge_modal {

    .bridge_modal_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        width: 100%;
        padding: 0 83px;

        .header_texts {
            padding-top: 24px;
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #333333;
            margin-bottom: 50px;
        }

        .transfer_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 5px;
            align-items: center;
        }

        .ethereum_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }

        .polygon_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }

        .modeIcon {
            width: 40px;
            height: 40px;
        }

        .modeText {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #828282;
        }

        .trasferArrow {
            height: 25px;
        }

        .info_icon {
            margin-right: 5px;
        }

        .processing {
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #333333;
            margin-top: 50px;
        }

        .view {
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            letter-spacing: 0.2px;
            color: #2330D0;
            margin-left: 12px;
        }

        .bridge_spinner {
            color: #BCC6FC;
            height: 120px;
            width: 120px;
        }

        .etherScan {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            margin-top: 25px;
        }

        .bottom_texts {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.1px;
            color: #333333;
            margin-top: 20px;
        }
        .bottom_link {
            margin: 20px 0 10px;
            display: flex;
            justify-content: center;
        
            a {
                font: normal normal 400 14px/18px 'Curse Casual';
                letter-spacing: 0.2px;
                color: #2330D0;
                display: flex;
                align-items: center;
                
                &:hover {
                    text-decoration: none;
                    color: #2330D0;
                }
        
                img {
                    margin-right: 8px;
                }
                
            }
        }

        .special_text {
            font-weight: bold;
        }

        .success_info {
            font-family: Curse Casual;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #333333;
            margin-top: 20px;
        }
    }
}