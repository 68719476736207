.tutorial_step {
    background-color: white;
    color: black;
    border-radius: 12px;
    padding: 10px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    p {
        color: black;
    }

    .step_num {
        font-size: 32px;
        line-height: 40px;
        font-family: 'Curse Casual';
        font-weight: bold;
        position: absolute;
    }

    .img_container {
        width: 80%;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    .align_bottom {
        margin-top: auto;
    }
}