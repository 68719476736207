.social_media_sidebar {
    display: flex;
    flex-direction: column;
    // height: 204px;
    height: fit-content;
    width: 44px;
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    z-index: 101;
    overflow: hidden;
    transition: 0.2s;
    -webkit-transition: 0.2s;

    background: rgba(0, 0, 0, 0.7);
    border-radius: 8px 0px 0px 8px;

    @media (max-width: 500px) {
        bottom: 10px;
    }

    &.extend {
        // width: 88px;
        transition: 0.2s;
        -webkit-transition: 0.2s;
    }

    .social_links {
        display: flex;
        flex-direction: row;
        -webkit-user-select: none; 
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .links_set {
            .link {
                width: 32px;
                height: 32px;
                margin: 8px 6px 2px 6px;
                
                img {
                    width: 100%;
                    height: 100%;
                }

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .socials_extend_menu {
        // position: absolute;
        position: relative;
        right: 0;
        bottom: 5px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 8px 6px 2px 6px;
    }

    .socials_extend_menu:hover {
        cursor: pointer;
    }
}

