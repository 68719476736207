.pte_box {
    border-radius: 8px;
    box-shadow: 0px 3px 12px #0000001A;
    background-color: white;
    overflow: hidden;
    width: 100%;
    max-width: 450px;

    .pte_header {
        background-color: #0D1C2B;
        color: #FFF;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .header1 {
            font: normal normal normal 20px/23px Curse Casual;
        }

        .header2 {
            font: normal normal normal 14px/19px Roboto;
            margin-top: 4px;
            width: 70%;
            text-align: center;
        }
    }

    .pte_body {
        padding: 12px 12px 36px 12px;

        .games_section {
            display: flex;
            justify-content: space-evenly;

            @media (max-width: 600px) {
                flex-direction: column;
            }

            .game_container {
                flex: 0 0 45%;

                .description {
                    font: normal normal normal 14px/20px Roboto;
                    color: #0D1C2B;
                    margin: 1em 0;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media (min-width: 600px) {
                        width: 190px;
                        height: 120px;
                    }
                }

                .game_logo_container {
                    width: 100%;
                    height: 85px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .game_logos {
                        width: 100%;
                    }
                }

                .game_links {
                    display: flex;
                    
                    img {
                        margin-right: 12px;
                    }

                    .qrLogo {
                        width: 68px;
                        height: 68px;
                    }

                    .non_mobile {
                        @media (max-width: 600px) {
                            display: none;
                        }
                    }
                }
            }
        }

        .more_details {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1.5em 0;

            .btn_connect {
                flex: 0.7;
                text-transform: uppercase;
            }
        }

        .claim_text {
            display: flex;
            justify-content: center;
            font: normal normal normal 20px/23px Curse Casual;
            color: #0D1C2B;
        }
    }
}
