.connect_modal {
    border-radius: 4px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    text-align: center;
    font: normal normal normal 14px/20px Roboto;
    letter-spacing: 0px;
    color: #0D1C2B;

    .close_btn {
        font-size: 22px;
        position: absolute;
        top: 6px;
        right: 12px;
        color: #2330D0;
        cursor: pointer;
    }

    .title {
        font: normal normal normal 20px/30px Curse Casual;
    }

    .wallets_section {
        .choose_wallet {
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid #2330D0;
            color: #2330D0;
            width: 152px;
            height: 120px;
            text-align: center;
            font: normal normal normal 14px/24px Curse Casual;
            letter-spacing: 0.7px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 18px;

            img {
                width: 56px;
                height: 56px;
                margin-left: -5px;
            }
        }

        .wallet_description {
            margin-top: 8px;
        }

        .active {
            background-color: #2330D0;
            color: #FFF;
        }
    }

    .connect_section {
        margin-top: 24px;

        .modal_btn_connect {
            text-transform: uppercase;
            width: 150px;
        }

        .connect_description {
            margin-top: 6px;
        }
    }
}
